<template>
    <div class="default-tooltip-box">
        <div>{{ title }}</div>
        <n-scrollbar trigger="none" :style="{ maxHeight: maxHeight }">
            <div
                v-for="item in seriesItems"
                class="series-item"
                :style="{
                    backgroundColor:
                        item.seriesName === selectSeriesName ? '#efefef' : '',
                }"
                @click="itemSelect(item)">
                <div class="series-name" :title="item.seriesName">
                    <span
                        class="marker"
                        :style="{ backgroundColor: item.color }"></span>
                    {{ item.seriesName }}
                </div>
                <div>
                    <span style="font-weight: bold">{{
                        typeof item.value === 'number'
                            ? `${valFormatter(item.value)}${getRatio(item)}`
                            : '-'
                    }}</span>
                    <slot name="itemSuffix" :series="item"></slot>
                </div>
            </div>
        </n-scrollbar>
        <div
            v-if="props.option.seriesTotal && props.option.seriesTotal.show"
            class="series-item"
            style="font-weight: bold">
            <div style="font-weight: bold; padding-left: 8px">总计</div>
            <div>{{ valFormatter(seriesItemValueSum) }}</div>
        </div>
    </div>
</template>

<style lang="less" scoped>
.default-tooltip-box {
    .series-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 2px 12px 2px 0;
        cursor: pointer;

        .series-name {
            margin-right: 10px;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            .marker {
                display: inline-block;
                margin-right: 4px;
                border-radius: 10px;
                width: 10px;
                height: 10px;
            }
        }
    }
}
</style>

<script setup>
import { ref, computed } from 'vue';

import { NScrollbar } from 'naive-ui';

const props = defineProps({
    data: {
        type: Object,
    },
    option: {
        type: Object,
        default: () => ({}),
    },
});

const emits = defineEmits(['itemClick']);

const title = computed(() => props.data[0].axisValueLabel);

const maxHeight = computed(() => {
    if (!props.option.maxHeight) {
        return '300px';
    }
    if (Number.isFinite(props.option.maxHeight)) {
        return props.option.maxHeight + 'px';
    }
    return props.option.maxHeight;
});

const valFormatter = computed(
    () => props.option.valueFormatter || (value => value)
);

let seriesItemValueSum = ref(0);

const seriesItems = computed(() => {
    let valuableItems = props.data.filter(
        item => typeof item.value === 'number'
    );
    let emptyValItems = props.data.filter(
        item => typeof item.value !== 'number'
    );
    valuableItems.sort((a, b) => -1 * (a.value - b.value));
    // 显示每个系列值在x轴所有系列值总和值的占比
    let sum = 0;
    valuableItems.forEach(item => {
        sum += item.value;
    });
    seriesItemValueSum.value = sum;
    return [...valuableItems, ...emptyValItems];
});

function getRatio(item) {
    return props.option.seriesItemRatio && props.option.seriesItemRatio.show
        ? ` (${((item.value / seriesItemValueSum.value) * 100).toFixed(2)}%)`
        : '';
}

let selectSeriesName = ref('');

function itemSelect(item) {
    selectSeriesName.value =
        selectSeriesName.value === item.seriesName ? '' : item.seriesName;
    emits('itemClick', item);
}
</script>
