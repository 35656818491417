/**
 * @file Use
 */

import { computed, watch } from 'vue';

import regionMap from '@/common/regionMap.json';

export function useTags(route) {
    return computed(() => {
        if (!route.query.tags) {
            return null;
        }
        return route.query.tags.split(',');
    });
}

export function useRouteWatcher(route) {
    return callback => {
        return watch(route, val => {
            if (val.name !== 'collection_view') {
                return;
            }
            callback && callback();
        });
    };
}

export function aliasRegionLabel(code) {
    return regionMap[code] ? `${regionMap[code]}[${code}]` : code;
}
