<template>
    <n-drawer
        :show="props.show"
        width="65%"
        :placement="props.placement"
        @mask-click="onMaskClick"
    >
        <n-drawer-content :body-style="{ backgroundColor: '#f9f9f9' }">
            <template #header>
                <div class="drawer-header">
                    {{ props.collection.name || '查看' }}
                    <div class="right-ctrl-view" v-if="!props.review">
                        <n-button
                            class="btn"
                            size="small"
                            secondary
                            @click="redirectToAPIDoc"
                            >API</n-button
                        >
                        <n-button
                            v-if="props.showEdit"
                            class="btn"
                            size="small"
                            @click="onEditClick"
                            >编辑</n-button
                        >
                        <n-button
                            class="btn"
                            size="small"
                            type="primary"
                            ghost
                            @click="toggleViewType"
                            >{{ toggleBtnText }}</n-button
                        >
                    </div>
                </div>
            </template>
            <template v-if="viewType === 'data'">
                <div v-if="showCtrlBar" class="ctrl-bar">
                    <div class="label">日期：</div>
                    <f-data-picker
                        v-model:date="dateRes"
                        class="input"
                        style="width: 220px"
                    ></f-data-picker>
                    <div class="label">粒度：</div>
                    <n-radio-group
                        v-model:value="granularity"
                        name="granularity"
                        size="small"
                        class="input"
                    >
                        <n-radio-button label="日" value="day"></n-radio-button>
                        <n-radio-button
                            label="周"
                            value="week"
                        ></n-radio-button>
                        <n-radio-button
                            label="相对周"
                            value="7"
                        ></n-radio-button>
                        <n-radio-button
                            label="月"
                            value="month"
                        ></n-radio-button>
                    </n-radio-group>
                    <n-button
                        style="margin-left: auto"
                        size="small"
                        type="primary"
                        class="submit"
                        @click="onQueryClick"
                    >
                        <template #icon>
                            <n-icon>
                                <SearchFilled />
                            </n-icon>
                        </template>
                        查询
                    </n-button>
                </div>
                <div v-else class="collection-info">
                    <div class="label">
                        日期：{{ props.queryObj.start_time }} ~
                        {{ props.queryObj.end_time }}
                        <n-tag type="info">
                            粒度:
                            {{
                                {
                                    day: '日',
                                    week: '周',
                                    month: '月',
                                    7: '相对周',
                                }[props.queryObj.granularity]
                            }}
                        </n-tag>
                    </div>
                </div>
                <div class="chart-content">
                    <CostChart
                        class="item"
                        title="总体成本趋势"
                        dimension="all"
                        :params="costParams"
                        :id="props.collection.id"
                    ></CostChart>
                    <CostChart
                        class="item"
                        title="Team&Group成本分布"
                        dimension="leaf"
                        :params="costParams"
                        :id="props.collection.id"
                    ></CostChart>
                    <CostChart
                        class="item"
                        title="服务成本分布"
                        dimension="service"
                        :params="costParams"
                        :id="props.collection.id"
                    ></CostChart>
                    <CostChart
                        class="item"
                        title="细分计费分布"
                        dimension="detail"
                        :params="costParams"
                        :id="props.collection.id"
                    ></CostChart>
                    <CostChart
                        class="item"
                        title="地域成本分布"
                        dimension="region"
                        :params="costParams"
                        :id="props.collection.id"
                    ></CostChart>
                    <CostChart
                        class="item"
                        title="云商成本分布"
                        dimension="cloud"
                        :params="costParams"
                        :id="props.collection.id"
                    ></CostChart>
                </div>
            </template>
            <template v-else>
                <div class="collection-info">
                    <div class="label">管理员</div>
                    <div class="content">{{ props.collection.admin }}</div>
                    <div class="label">标签</div>
                    <div class="content">
                        <n-tag
                            v-for="item in props.collection.tags"
                            class="tag"
                            :bordered="false"
                            type="info"
                            round
                        >
                            {{ item }}
                        </n-tag>
                    </div>
                    <div class="label">Team&Group</div>
                    <div class="content">
                        <n-tag v-for="item in teamGroups" class="tag">{{
                            item.team + ':' + item.group
                        }}</n-tag>
                    </div>
                    <template
                        v-if="!isEmptyObject(props.collection.feFilterState)"
                    >
                        <div class="label">Filters</div>
                        <div class="content">
                            <p>
                                Service：{{
                                    props.collection.feFilterState.filter
                                        .service
                                }}
                                {{
                                    props.collection.feFilterState.excludeKeys
                                        .service
                                        ? '(排除)'
                                        : ''
                                }}
                            </p>
                            <p>
                                细分计费：{{
                                    props.collection.feFilterState.filter.detail
                                }}
                                {{
                                    props.collection.feFilterState.excludeKeys
                                        .detail
                                        ? '(排除)'
                                        : ''
                                }}
                            </p>
                            <p>
                                地域：{{
                                    props.collection.feFilterState.filter.region
                                }}
                                {{
                                    props.collection.feFilterState.excludeKeys
                                        .region
                                        ? '(排除)'
                                        : ''
                                }}
                            </p>
                            <p>
                                云商：{{
                                    props.collection.feFilterState.filter.cloud
                                }}
                                {{
                                    props.collection.feFilterState.excludeKeys
                                        .cloud
                                        ? '(排除)'
                                        : ''
                                }}
                            </p>
                            <p>
                                RI开关：{{
                                    ['均摊RI', '还原RI', '排除RI'][
                                        props.collection.feFilterState.filter
                                            .purchase
                                    ]
                                }}
                            </p>
                            <p>
                                SavingPlan未使用开关：
                                {{
                                    props.collection.feFilterState.filter
                                        .savingpu === 1
                                        ? '开'
                                        : '关'
                                }}
                            </p>
                        </div>
                    </template>
                </div>
            </template>
        </n-drawer-content>
    </n-drawer>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';

.drawer-header {
    width: 61.4vw;
    position: relative;

    .right-ctrl-view {
        position: absolute;
        right: 0;
        top: -4px;

        .btn {
            margin-left: 8px;
        }
    }
}

.ctrl-bar {
    .common-ctrl-bar;
    margin-bottom: 10px;
}

.chart-content {
    .item {
        margin-bottom: 10px;
    }
}

.collection-info {
    background-color: #fff;
    .standard-border;
    .standard-shadow;
    padding: 10px 14px;

    .label {
        font-weight: bold;
    }

    .content {
        margin: 10px 0;
        .tag {
            margin-right: 4px;
            margin-bottom: 4px;
        }
    }
}
</style>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue';

import {
    NDrawer,
    NDrawerContent,
    NRadioGroup,
    NRadioButton,
    NButton,
    NIcon,
    NTag,
} from 'naive-ui';

import dayjs from 'dayjs';

import { SearchFilled } from '@vicons/material';

import FDataPicker from '@/components/FinOpsDatePicker';

import CostChart from './CostChart';

import { quickRangeMap } from '@/common/QueryDateRange';

import { isEmptyObject } from '@/common/tools';

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    collection: {
        type: Object,
        default: () => ({}),
    },
    placement: {
        type: String,
        default: 'right',
    },
    review: {
        type: Boolean,
        default: false,
    },
    showCtrlBar: {
        type: Boolean,
        default: true,
    },
    queryObj: {
        type: Object,
        default: () => ({}),
    },
    showEdit: {
        type: Boolean,
        default: false,
    },
});

watch(
    () => props.show,
    value => {
        if (value && Object.keys(props.queryObj).length === 0) {
            onQueryClick();
        }
    }
);

const emits = defineEmits(['update:show', 'editClick']);

function onMaskClick() {
    emits('update:show', false);
    clearState();
}

function onEditClick() {
    emits('editClick');
}

function clearState() {
    viewType.value = 'data';
    dateRes.value = { type: 'last7' };
    granularity.value = 'day';
}

let viewType = ref('data');

function toggleViewType() {
    viewType.value = viewType.value === 'data' ? 'info' : 'data';
}

let toggleBtnText = computed(
    () => ({ data: '数据集信息', info: '成本数据' }[viewType.value])
);

let dateRes = ref({ type: 'last7' });

const FORMAT = 'YYYY-MM-DD';

function getDateRangeByFDateModel(model) {
    if (model.range) {
        return [
            dayjs(model.range[0]).format(FORMAT),
            dayjs(model.range[1]).format(FORMAT),
        ];
    }
    return quickRangeMap()[model.type || 'last7'].query();
}

let granularity = ref('day');

let costParams = ref({});

function onQueryClick() {
    costParams.value = {
        granularity: granularity.value,
        dateRange: getDateRangeByFDateModel(dateRes.value),
    };
}

function query() {
    costParams.value = {
        granularity: chartObj.value.granularity,
        dateRange: getDateRangeByFDateModel(chartObj.value.dateRes),
    };
}

const teamGroups = computed(() => props.collection.teamgroups || []);

function redirectToAPIDoc() {
    window.open(
        'https://alidocs.dingtalk.com/i/p/ZR2PmK51081zvpO7l2AmoaQW4n8EEzdb',
        '_blank'
    );
}

let chartObj = ref({});

watch(
    () => props.queryObj,
    (newVal, oldVal) => {
        chartObj.value = newVal;
        if (chartObj.value.dateRes.type) {
            dateRes.value = { type: chartObj.value.dateRes.type };
        } else {
            dateRes.value = {
                range: chartObj.value.dateRes.range,
            };
        }
        granularity.value = chartObj.value.granularity;
        query();
    }
);
</script>
