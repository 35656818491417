<template>
    <div class="finops-date-picker">
        <n-popover
            v-model:show="showPopover"
            trigger="click"
            placement="bottom-start"
            :show-arrow="false"
            raw
            @clickoutside="onCanceled"
        >
            <template #trigger>
                <n-input
                    v-model:value="selectedDateText"
                    :placeholder="props.placeholder"
                    size="small"
                    readonly
                >
                    <template #suffix>
                        <n-icon size="tiny">
                            <CalendarTodayFilled />
                        </n-icon>
                    </template>
                </n-input>
            </template>
            <div class="finops-date-picker-pop">
                <div class="picker-grid">
                    <div class="quick-items">
                        <div class="label">
                            相对日期区间
                            <n-tooltip>
                                <template #trigger>
                                    <n-icon
                                        size="tiny"
                                        style="translate: 0 2px"
                                    >
                                        <HelpOutlineFilled />
                                    </n-icon>
                                </template>
                                <span>以当前日期为基准选择日期区间</span>
                            </n-tooltip>
                        </div>
                        <n-scrollbar style="max-height: 228px">
                            <n-button
                                v-for="item in quickItems"
                                class="item"
                                size="small"
                                :type="
                                    quickDateRangeType === item.key
                                        ? 'primary'
                                        : 'default'
                                "
                                @click="setQuickDate(item.key)"
                                block
                                >{{ item.label }}</n-button
                            >
                        </n-scrollbar>
                    </div>
                    <div class="date-range-panel">
                        <n-date-picker
                            v-model:value="abDateRange"
                            panel
                            type="daterange"
                            size="small"
                            clearable
                            :actions="null"
                            :first-day-of-week="6"
                            :is-date-disabled="isDateDisabled"
                            @update:value="onDateUpdate"
                        />
                    </div>
                </div>
                <div class="bottom-panel">
                    <div class="date-label">
                        <!-- <n-input
                            :value="[dateRangeText.start, dateRangeText.end]"
                            size="tiny"
                            separator="~"
                            pair
                            style="width: 240px"
                            :allow-input="allowDateString"
                            @update:value="onDateInputUpdate"
                        >
                            <template #prefix>
                                <n-icon>
                                    <DateRangeFilled />
                                </n-icon>
                            </template>
                        </n-input> -->
                        <n-icon style="translate: 0 1.5px">
                            <DateRangeFilled />
                        </n-icon>
                        <span>{{ dateRangeText.start }}</span>
                        <n-icon
                            v-if="dateRangeText.start"
                            size="small"
                            style="translate: 0 1px; margin: 0 4px"
                        >
                            <EastFilled />
                        </n-icon>
                        <span>{{ dateRangeText.end }}</span>
                    </div>
                    <div>
                        <n-button
                            v-if="props.clearable"
                            style="margin-right: 8px"
                            type="primary"
                            secondary
                            size="tiny"
                            @click="onClear"
                            >清除</n-button
                        >
                        <n-button
                            type="primary"
                            size="tiny"
                            @click="onSelected"
                            >{{ submitText }}</n-button
                        >
                    </div>
                </div>
            </div>
        </n-popover>
    </div>
</template>

<style lang="less" scoped>
.finops-date-picker {
    display: inline-block;
}

.finops-date-picker-pop {
    background-color: #fff;
    margin-top: -4px;
    .bottom-panel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 8px 16px;
        border-top: 1px solid #eee;
        .date-label {
            font-weight: bold;
            span {
                font-weight: normal;
                padding: 0 4px;
            }
        }
    }

    .picker-grid {
        display: flex;
        flex-direction: row;

        .quick-items {
            width: 110px;
            padding: 0px 10px 0;
            border-right: 1px solid #eee;

            .label {
                text-align: center;
                font-weight: bold;
                padding: 13px 0;
                line-height: 14px;
            }

            .item {
                margin-bottom: 4px;
            }
        }
    }
}
</style>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';

import {
    NInput,
    NIcon,
    NPopover,
    NTooltip,
    NButton,
    NDatePicker,
    NScrollbar,
} from 'naive-ui';

import {
    CalendarTodayFilled,
    DateRangeFilled,
    EastFilled,
    HelpOutlineFilled,
} from '@vicons/material';

import dayjs from 'dayjs';

import { quickRangeMap } from '@/common/QueryDateRange';

import { isEmptyArray } from '@/common/tools';

const FORMAT = 'YYYY-MM-DD';

const quickMap = quickRangeMap();

const props = defineProps({
    date: {
        type: Object,
        default: () => ({ type: '', range: null }),
    },
    submitText: {
        type: String,
        default: '确认',
    },
    isDateDisabled: {
        type: Function,
    },
    placeholder: {
        type: String,
        default: '选择日期区间',
    },
    clearable: {
        type: Boolean,
        default: false,
    },
});

const emits = defineEmits(['update:date']);

let showPopover = ref(false);

let selectedDateText = ref('');

let abDateRange = ref(null);

let quickDateRangeType = ref('');

let quickItems = Object.keys(quickMap).map(key => ({ key, ...quickMap[key] }));

let dateRangeText = computed(() => {
    let start = '';
    let end = '';
    let range = abDateRange.value;
    if (range && range[0]) {
        start = dayjs(range[0]).format(FORMAT);
    }
    if (range && range[1]) {
        end = dayjs(range[1]).format(FORMAT);
    }
    return { start, end };
});

let isQuickSelect = false;
let selectQuickLabel = '';

function setQuickDate(key) {
    if (quickDateRangeType.value === key) {
        return;
    }
    quickDateRangeType.value = key;
    abDateRange.value = quickMap[key].ts();
    selectQuickLabel = quickMap[key].label;
    isQuickSelect = true;
}

// 通过操作日期组件触发的update
function onDateUpdate() {
    isQuickSelect = false;
    quickDateRangeType.value = '';
    selectQuickLabel = '';
}

// function allowDateString(value) {
//     console.log(value);
//     return dayjs(value).isValid();
// }

// function onDateInputUpdate(value) {
//     isQuickSelect = false;
//     quickDateRangeType.value = '';
//     selectQuickLabel = '';
//     // console.log('xx');
// }

let stateUpdated = false;

function onSelected() {
    selectedDateText.value = isQuickSelect
        ? selectQuickLabel
        : `${dateRangeText.value.start} ~ ${dateRangeText.value.end}`;
    showPopover.value = false;

    let emitsArgs = {
        type: isQuickSelect ? quickDateRangeType.value : null,
        range: abDateRange.value,
    };
    stateUpdated = true;
    emits('update:date', emitsArgs);
}

function onCanceled() {
    let { range, type } = props.date;
    if (!isEmptyArray(range)) {
        abDateRange.value = range;
        isQuickSelect = false;
        quickDateRangeType.value = type;
        selectQuickLabel = '';
        // 传入合法range时，则无需再根据相对时间type推导range
        return;
    }
    if (type && quickMap[type]) {
        isQuickSelect = true;
        quickDateRangeType.value = type;
        abDateRange.value = quickMap[type].ts();
        selectQuickLabel = quickMap[type].label;
    }
}

function onClear() {
    abDateRange.value = null;
    quickDateRangeType.value = '';
    selectQuickLabel = '';
    isQuickSelect = false;
    selectedDateText.value = '';
    showPopover.value = false;
    emits('update:date', { type: '', range: null });
}

function initState() {
    let { range, type } = props.date;
    if (!isEmptyArray(range)) {
        abDateRange.value = range;
        selectedDateText.value = `${dateRangeText.value.start} ~ ${dateRangeText.value.end}`;
        // 传入合法range时，则无需再根据相对时间type推导range
        return;
    }
    if (type && quickMap[type]) {
        isQuickSelect = true;
        quickDateRangeType.value = type;
        abDateRange.value = quickMap[type].ts();
        selectedDateText.value = quickMap[type].label;
        selectQuickLabel = quickMap[type].label;
    }
}

watch(
    () => props.date,
    val => {
        if (stateUpdated) {
            stateUpdated = false;
            return;
        }
        initState();
    }
);

onMounted(() => {
    initState();
});
</script>
