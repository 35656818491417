/**
 * @file FinOps标准日期区间转换器
 */

import { computed } from 'vue';

import dayjs from 'dayjs';

import { useGlobalStore } from '@/stores/global';

const globalStore = useGlobalStore();

const FORMAT = 'YYYY-MM-DD';

const defaultMap = {
    last7: {
        label: '最近7天',
        query() {
            let td = today();
            return [td.subtract(6, 'day').format(FORMAT), td.format(FORMAT)];
        },
        ts() {
            let td = today();
            return [td.subtract(6, 'day').valueOf(), td.valueOf()];
        },
    },
    last14: {
        label: '最近14天',
        query() {
            let td = today();
            return [td.subtract(13, 'day').format(FORMAT), td.format(FORMAT)];
        },
        ts() {
            let td = today();
            return [td.subtract(13, 'day').valueOf(), td.valueOf()];
        },
    },
    last30: {
        label: '最近30天',
        query() {
            let td = today();
            return [td.subtract(29, 'day').format(FORMAT), td.format(FORMAT)];
        },
        ts() {
            let td = today();
            return [td.subtract(29, 'day').valueOf(), td.valueOf()];
        },
    },
    last2m: {
        label: '最近2个月',
        query() {
            let td = today();
            return [
                td.subtract(1, 'month').startOf('month').format(FORMAT),
                td.format(FORMAT),
            ];
        },
        ts() {
            let td = today();
            return [
                td.subtract(1, 'month').startOf('month').valueOf(),
                td.valueOf(),
            ];
        },
    },
    last3m: {
        label: '最近3个月',
        query() {
            let td = today();
            return [
                td.subtract(2, 'month').startOf('month').format(FORMAT),
                td.format(FORMAT),
            ];
        },
        ts() {
            let td = today();
            return [
                td.subtract(2, 'month').startOf('month').valueOf(),
                td.valueOf(),
            ];
        },
    },
    last6m: {
        label: '最近6个月',
        query() {
            let td = today();
            return [
                td.subtract(5, 'month').startOf('month').format(FORMAT),
                td.format(FORMAT),
            ];
        },
        ts() {
            let td = today();
            return [
                td.subtract(5, 'month').startOf('month').valueOf(),
                td.valueOf(),
            ];
        },
    },
    before3m: {
        label: '前3个月',
        query() {
            let td = today();
            return [
                td.subtract(3, 'month').startOf('month').format(FORMAT),
                td.startOf('month').subtract(1, 'day').format(FORMAT),
            ];
        },
        ts() {
            let td = today();
            return [
                td.subtract(3, 'month').startOf('month').valueOf(),
                td.startOf('month').subtract(1, 'day').valueOf(),
            ];
        },
    },
    before6m: {
        label: '前6个月',
        query() {
            let td = today();
            return [
                td.subtract(6, 'month').startOf('month').format(FORMAT),
                td.startOf('month').subtract(1, 'day').format(FORMAT),
            ];
        },
        ts() {
            let td = today();
            return [
                td.subtract(6, 'month').startOf('month').valueOf(),
                td.startOf('month').subtract(1, 'day').valueOf(),
            ];
        },
    },
};

export function today() {
    return dayjs().add(globalStore.todayOffset, 'day');
}

export function quickRangeMap() {
    return defaultMap;
}

export function useDateRange(route) {
    // 关于日期模式优先级，当route中同时存在绝对日期模式和相对日期模式的参数时，优先识别绝对日期
    return computed(() => {
        function res(start, end) {
            return { start_time: start, end_time: end };
        }
        // 绝对日期模式，必须有end
        if (route.query.end) {
            let start = route.query.start
                ? dayjs(+route.query.start)
                : today().subtract(6, 'day');
            return res(
                start.format(FORMAT),
                dayjs(+route.query.end).format(FORMAT)
            );
        }
        // 相对日期模式，如果没有指定qdt，则默认返回最近7天
        let qdt = defaultMap[route.query.qdt] || defaultMap.last7;
        let qdtQuery = qdt.query();
        return res(qdtQuery[0], qdtQuery[1]);
    });
}
