/**
 * @file 数据标签层
 */

import Konva from 'konva';
import { sizeTool } from './Tools';

export default function draw(stage) {
    // 此处引入画布dom，用来控制标签hover时的鼠标样式
    let container = stage.container();
    const { size } = sizeTool(stage.width(), stage.height());

    let layer = new Konva.Layer();

    let state = {};

    const labelFontSize = size(15);

    function onLabelMouseEnter(e) {
        container.style.cursor = 'pointer';
        let key = e.target.name();
        if (!state[key]) {
            return;
        }
        e.target.textDecoration('underline');
    }

    function update() {
        for (let key in state) {
            let v = state[key];
            if (!components[key]) {
                continue;
            }
            let labelText = components[key].getText();
            if (v.value.toString() !== labelText.text()) {
                labelText.text(v.value);
            }
        }
    }

    function createLabelInstance(
        x,
        y,
        transparent = true,
        fontSize = labelFontSize
    ) {
        let label = new Konva.Label({
            x: size(x),
            y: size(y),
        });
        if (!transparent) {
            label.add(
                new Konva.Tag({
                    fill: '#fff',
                })
            );
        }
        let text = new Konva.Text({
            x: 0,
            y: 0,
            text: '',
            fontSize,
        });
        label.add(text);

        text.on('mouseenter', onLabelMouseEnter);
        text.on('mouseout', e => {
            container.style.cursor = 'default';
            e.target.textDecoration('');
        });
        text.on('click', () => {
            labelClickCallback && labelClickCallback(text.name());
        });
        return label;
    }

    let components = {
        total: createLabelInstance(280,48),
        ssp_tracking_total: createLabelInstance(260,271),
        php_tracking_total: createLabelInstance(48,330),
        adx_tracking_lb: createLabelInstance(304,228),
        adx_tracking_total: createLabelInstance(324,309),
        adx_tracking_compute: createLabelInstance(300,334),
        adx_tracking_region: createLabelInstance(300,359),
        adx_tracking_sqs: createLabelInstance(300,384),
        adx_tracking_other: createLabelInstance(300,409),
        ad_track_lb: createLabelInstance(430,196),
        ad_track_total: createLabelInstance(580,309),
        ad_track_compute: createLabelInstance(580,335),
        ad_track_cache: createLabelInstance(580,360),
        ad_track_region: createLabelInstance(580,385),
        ad_track_az: createLabelInstance(580,410),
        ad_track_sqs: createLabelInstance(580,435),
        ad_track_other: createLabelInstance(580,460),
        ajump_outnet: createLabelInstance(862,222),
        ajump_lb: createLabelInstance(694,402),
        ajump_total: createLabelInstance(855,310),
        ajump_compute: createLabelInstance(855,335),
        ajump_region: createLabelInstance(855,360),
        ajump_az: createLabelInstance(855,385),
        ajump_other: createLabelInstance(855,410),
        ad_postback_outnet: createLabelInstance(1211,231),
        ad_postback_lb: createLabelInstance(1035,216),
        ad_postback_total: createLabelInstance(1145,308),
        ad_postback_compute: createLabelInstance(1145,335),
        ad_postback_region: createLabelInstance(1145,360),
        ad_postback_other: createLabelInstance(1145,385),
        roi_total: createLabelInstance(1315,337),
        gather_match_total: createLabelInstance(637,586),
        gather_match_compute: createLabelInstance(580,607),
        gather_match_outnet: createLabelInstance(580,629),
        gather_match_other: createLabelInstance(580,650),
        gather_tracking_total: createLabelInstance(790,732),
        gather_tracking_compute: createLabelInstance(610,752),
        gather_tracking_outnet: createLabelInstance(610,773),
        gather_tracking_other: createLabelInstance(610,792),
        sqs_total: createLabelInstance(1115,621),
        collect_and_match_total: createLabelInstance(1107,749),
        collect_and_match_compute: createLabelInstance(1107,779),
        collect_and_match_other: createLabelInstance(1107,804),
        r_tracking_total: createLabelInstance(1315,580),
        dynamodb_total: createLabelInstance(1337,875),
        demand_tracking_total: createLabelInstance(1049,271),
        geo_total: createLabelInstance(1747,309),
        treasurebox_total: createLabelInstance(1780,435),
    };

    for (let key in components) {
        let label = components[key];
        label.getText().name(key);
        layer.add(label);
    }

    stage.add(layer);

    let labelClickCallback = null;

    return {
        layer,
        setData(data) {
            state = data;
            // for (let key in components) {
            //     components[key].getText().text('');
            // }
            update();
        },
        onLabelClick(listener) {
            labelClickCallback = listener;
        },
    };
}
