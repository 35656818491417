/**
 * @file EChartsTools
 * @description 和Echarts图表相关的一些周边工具
 */

import { render, h } from 'vue';
import { formatNumber, isNullOrUndefined } from './tools';
import DefaultTooltipBox from '@/components/TooltipBox/DefaultTooltip';

// 自定义legendSelect的操作行为，主要作用是人为控制legend的selected状态，不按照默认行为执行
export function customLegendSelected(chartInstance, behavior, func) {
    chartInstance.on('legendselectchanged', params => {
        func && func(params);
        if (!behavior) {
            return;
        }
        let selected = behavior(params);
        let opt = chartInstance.getOption();
        opt.legend[0].selected = selected;
        chartInstance.setOption(opt);
    });
}

export class LegendBehavior {
    // 切换单展现一个series和展现全部series
    static toggleSingleOrAll() {
        let lastSelect = '';
        return function (params) {
            let selected = params.selected;
            for (let key in selected) {
                if (key === params.name) {
                    selected[key] = true;
                    continue;
                }
                selected[key] = params.name === lastSelect;
            }
            lastSelect = lastSelect === params.name ? '' : params.name;
            return selected;
        };
    }
}

export class AdvancedTooltip {
    container = null;

    constructor(container) {
        this.container = container || document.querySelector('body');
    }

    /**
     * 返回自定义后的echarts tooltip配置数据
     * @param {*} option
     * @param {number} option.maxHeight tooltip视窗最大高度，超过该高度时将出现滚动条
     * @param {Function} option.valueFormatter 功能同echarts.tooltip 因整体Tooltip自定义，需要重写
     * @param {Function} option.vNodeCreator tooltipbox自定义组件vNode Hook
     * @param {*} option.opt 传入echarts.tooltip原生配置项，可覆盖除enterable,formatter以外的配置项
     * @returns
     */
    tooltip(option = {}) {
        return {
            trigger: 'axis',
            appendToBody: true,
            ...option.opt,
            enterable: true,
            transitionDuration: 0.8,
            formatter: params => {
                let instance = option.vNodeCreator
                    ? option.vNodeCreator(params, option)
                    : h(DefaultTooltipBox, {
                          data: params,
                          option,
                      });
                render(instance, this.container);
                return instance.el;
            },
        };
    }
}

export function yAxisLabelFormatter(type = 'number', formatter, opt = {}) {
    let axisLabelObj = {
        axisLabel: {
            ...opt,
            formatter,
        },
    };
    if (type !== 'custom') {
        let fixCount = isNullOrUndefined(opt.fixCount) ? 1 : opt.fixCount;
        axisLabelObj.axisLabel.formatter = {
            number: value => formatNumber(value, fixCount),
            percent: value => `${(value * 100).toFixed(fixCount)}%`,
        }[type];
    }
    return axisLabelObj;
}

// 支持seriesType:line bar scatter（图表有明确x轴的）
export function exportMatrixData(instance) {
    if (!instance) {
        return null;
    }
    let opt = instance.getOption();
    let listLength = opt.xAxis[0].data.length;
    let list = [];
    let columns = {};
    for (let i = 0; i < listLength; i++) {
        let xAxisItem = opt.xAxis[0].data[i];
        let obj = {
            x: xAxisItem,
        };
        let seriesData = opt.series;
        for (let j = 0; j < seriesData.length; j++) {
            columns[`column_${j}`] = seriesData[j].name;
            obj[`column_${j}`] = seriesData[j].data[i] || null;
        }
        list.push(obj);
    }
    return {
        list,
        columns,
    };
}
