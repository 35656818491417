import dayjs from 'dayjs';
export function convert(obj, cur, yoy, pre, ref, depth = 0) {
    const result = [];
  
    for (const c in obj) {
        if (obj.hasOwnProperty(c)) {
        const node = {
            key: cur[c].id,
            depth: depth,
            collection: cur[c].id,
            name: cur[c].name,
            admin: cur[c].admin,
            feFilterState: cur[c].feFilterState,
            tags: cur[c].tags,
            teamgroups: cur[c].teamgroups,
            current: cur[c].value === null ? 0 : (cur[c].value).toFixed(2),
            current_avg: cur[c].value_avg,
            yoy: yoy[c].value === null ? 0 : (yoy[c].value).toFixed(2),
            yoy_gap: (cur[c].value - yoy[c].value).toFixed(2),
            yoy_rate: yoy[c].value === 0 ? 'NULL' : ((cur[c].value - yoy[c].value) / yoy[c].value * 100).toFixed(2) + '%',
            pre: pre[c].value === null ? 0 : (pre[c].value).toFixed(2),
            pre_gap: (cur[c].value - pre[c].value).toFixed(2),
            pre_rate: pre[c].value === 0 ? 'NULL' : ((cur[c].value - pre[c].value) / pre[c].value * 100).toFixed(2) + '%',
            ref_avg: Object.keys(ref).length === 0 ? '/' : ref[c].value,
            ref_avg_gap: Object.keys(ref).length === 0 ? '/' : (cur[c].value_avg - ref[c].value).toFixed(2),
            ref_avg_rate: Object.keys(ref).length === 0  ? '/' : ref[c].value === 0 ? 'NULL' : ((cur[c].value_avg - ref[c].value) / ref[c].value * 100).toFixed(2) + '%',
        };

        const children = convert(obj[c], cur, yoy, pre, ref, depth + 1);
        if (children.length > 0) {
            node.children = children;
        }
        result.push(node);
        }
    }
    return result
};

export function convertWithoutRecursion(ori, obj, cur, yoy, pre, ref, depth) {
    const result = []
    for (const idx in obj) {
        const cid = obj[idx]

        const node = {
            key: cid,
            depth: depth,
            collection: cid,
            name: cur[cid].name,
            admin: cur[cid].admin,
            feFilterState: cur[cid].feFilterState,
            tags: cur[cid].tags,
            teamgroups: cur[cid].teamgroups,
            current: cur[cid].value === null ? 0 : (cur[cid].value).toFixed(2),
            current_avg: cur[cid].value_avg,
            yoy: yoy[cid].value === null ? 0 : (yoy[cid].value).toFixed(2),
            yoy_gap: (cur[cid].value - yoy[cid].value).toFixed(2),
            yoy_rate: yoy[cid].value === 0 ? 'NULL' : ((cur[cid].value - yoy[cid].value) / yoy[cid].value * 100).toFixed(2) + '%',
            pre: pre[cid].value === null ? 0 : (pre[cid].value).toFixed(2),
            pre_gap: (cur[cid].value - pre[cid].value).toFixed(2),
            pre_rate: pre[cid].value === 0 ? 'NULL' : ((cur[cid].value - pre[cid].value) / pre[cid].value * 100).toFixed(2) + '%',
            ref_avg: Object.keys(ref).length === 0 ? '/' : ref[cid].value,
            ref_avg_gap: Object.keys(ref).length === 0 ? '/' : (cur[cid].value_avg - ref[cid].value).toFixed(2),
            ref_avg_rate: Object.keys(ref).length === 0  ? '/' : ref[cid].value === 0 ? 'NULL' : ((cur[cid].value_avg - ref[cid].value) / ref[cid].value * 100).toFixed(2) + '%',
            isLeaf: Object.keys(ori[cid]).length === 0 ? true : false
        }
        result.push(node)
    }
    return result
}

export function getDimensionListVal(dimensionList, item, cloud, region) {
    if (cloud !== undefined && region !== undefined) {
        if (cloud === 'all' && region === 'all') {
            dimensionList = ['all']
        } else if (cloud !== 'all' && region === 'all') {
            dimensionList = ['cloud']
            item = cloud
        } else if (cloud === 'all' && region !== 'all') {
            dimensionList = ['region']
            item = region
        } else {
            dimensionList = ['cloud', 'region']
        }
    } else if (cloud !== undefined && region === undefined) {
        if (cloud === 'all') {
            dimensionList = ['all']
        } else {
            dimensionList = ['cloud']
            item = cloud
        }
    } else if (cloud === undefined && region !== undefined) {
        if (region === 'all') {
            dimensionList = ['all']
        } else {
            dimensionList = ['region']
            item = region
        }
    } else {
        dimensionList = ['all']
    }
    return [dimensionList, item]
};

export function convertDataModel(obj, cur, yoy, pre, ref, depth = 0) {
    const result = [];
  
    for (const c in obj) {
        if (obj.hasOwnProperty(c)) {
            const node = {
                key: cur[c].id,
                model_id: cur[c].model_id,
                query_id: cur[c].query_id,
                depth: depth,
                name: cur[c].name,
                current: cur[c].value === null ? 0 : (cur[c].value).toFixed(2),
                current_avg: cur[c].value_avg,
                yoy: yoy[c].value === null ? 0 : (yoy[c].value).toFixed(2),
                yoy_gap: (cur[c].value - yoy[c].value).toFixed(2),
                yoy_rate: yoy[c].value === 0 ? 'NULL' : ((cur[c].value - yoy[c].value) / yoy[c].value * 100).toFixed(2) + '%',
                pre: pre[c].value === null ? 0 : (pre[c].value).toFixed(2),
                pre_gap: (cur[c].value - pre[c].value).toFixed(2),
                pre_rate: pre[c].value === 0 ? 'NULL' : ((cur[c].value - pre[c].value) / pre[c].value * 100).toFixed(2) + '%',
                ref_avg: Object.keys(ref).length === 0 ? '/' : ref[c].value,
                ref_avg_gap: Object.keys(ref).length === 0 ? '/' : (cur[c].value_avg - ref[c].value).toFixed(2),
                ref_avg_rate: Object.keys(ref).length === 0  ? '/' : ref[c].value === 0 ? 'NULL' : ((cur[c].value_avg - ref[c].value) / ref[c].value * 100).toFixed(2) + '%',
            };

            const children = convertDataModel(obj[c], cur, yoy, pre, ref, depth + 1);
            if (children.length > 0) {
                node.children = children;
            }
            result.push(node);
        }
    }
    return result
};

export function convertDataModelWithoutRecursion(ori, obj, cur, yoy, pre, ref, depth) {
    const result = []
    for (const idx in obj) {
        const k = obj[idx]
        if(ori.hasOwnProperty(k)) {
            const node = {
                key: cur[k].id,
                model_id: cur[k].model_id,
                query_id: cur[k].query_id,
                depth: depth,
                name: cur[k].name,
                current: cur[k].value === null ? 0 : (cur[k].value).toFixed(2),
                current_avg: cur[k].value_avg,
                yoy: yoy[k].value === null ? 0 : (yoy[k].value).toFixed(2),
                yoy_gap: (cur[k].value - yoy[k].value).toFixed(2),
                yoy_rate: yoy[k].value === 0 ? 'NULL' : ((cur[k].value - yoy[k].value) / yoy[k].value * 100).toFixed(2) + '%',
                pre: pre[k].value === null ? 0 : (pre[k].value).toFixed(2),
                pre_gap: (cur[k].value - pre[k].value).toFixed(2),
                pre_rate: pre[k].value === 0 ? 'NULL' : ((cur[k].value - pre[k].value) / pre[k].value * 100).toFixed(2) + '%',
                ref_avg: Object.keys(ref).length === 0 ? '/' : ref[k].value,
                ref_avg_gap: Object.keys(ref).length === 0 ? '/' : (cur[k].value_avg - ref[k].value).toFixed(2),
                ref_avg_rate: Object.keys(ref).length === 0  ? '/' : ref[k].value === 0 ? 'NULL' : ((cur[k].value_avg - ref[k].value) / ref[k].value * 100).toFixed(2) + '%',
                isLeaf: Object.keys(ori[k]).length === 0 ? true : false
            }
            result.push(node)
        }
    }
    return result
}

export function computePreDate(granularity, start, end) {
    if (granularity === 'day') {
        let pre_start = dayjs(start).subtract(1, 'day').format('YYYY-MM-DD');
        let pre_end = dayjs(end).subtract(1, 'day').format('YYYY-MM-DD');
        return [pre_start, pre_end]
    } else if (granularity === 'week') {
        let pre_start = dayjs(start).subtract(1, 'week').format('YYYY-MM-DD');
        let pre_end = dayjs(end).subtract(1, 'week').format('YYYY-MM-DD');
        return [pre_start, pre_end]
    } else if (granularity === '7') {
        let pre_start = dayjs(start).subtract(7, 'day').format('YYYY-MM-DD');
        let pre_end = dayjs(end).subtract(7, 'day').format('YYYY-MM-DD');
        return [pre_start, pre_end]
    } else if (granularity === 'month') {
        let pre_start = dayjs(start).subtract(1, 'month').format('YYYY-MM-DD');
        let pre_end = dayjs(end).subtract(1, 'month').format('YYYY-MM-DD');
        return [pre_start, pre_end]
    } else {
        console.log("granularity error")
    }
}

export function computeYoyDate(granularity, start, end) {
    if (granularity === 'day') {
        let yoy_start = dayjs(start).subtract(7, 'day').format('YYYY-MM-DD');
        let yoy_end = dayjs(end).subtract(7, 'day').format('YYYY-MM-DD');
        return [yoy_start, yoy_end]
    } else if (granularity === 'week') {
        let yoy_start = dayjs(start).subtract(1, 'month').format('YYYY-MM-DD');
        let yoy_end = dayjs(end).subtract(1, 'month').format('YYYY-MM-DD');
        return [yoy_start, yoy_end]
    } else if (granularity === '7') {
        let yoy_start = dayjs(start).subtract(4, 'week').format('YYYY-MM-DD');
        let yoy_end = dayjs(end).subtract(4, 'week').format('YYYY-MM-DD');
        return [yoy_start, yoy_end]
    } else if (granularity === 'month') {
        let yoy_start = dayjs(start).subtract(1, 'year').format('YYYY-MM-DD');
        let yoy_end = dayjs(end).subtract(1, 'year').format('YYYY-MM-DD');
        return [yoy_start, yoy_end]
    } else {
        console.log("granularity error")
    }
}