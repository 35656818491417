const tableCollection = {
    'total': {},
    // 采集层
    'collection_total': {
        'collection_request_total': {
            'collection_request_frontier': {},
            'collection_request_ssp': {},
            'collection_request_madx': {},
            'collection_request_dsp': {},
        },
        'collection_request_analytics': {},
        'collection_request_tracking': {},
    },
    // 存储层
    'storage_total': {
        'storage_logdata_total': {
            'storage_logdata_log_total': {
                'storage_logdata_log_oss': {},
                'storage_logdata_log_s3': {},
            },
            'storage_logdata_data_total': {
                'storage_logdata_data_oss': {},
                'storage_logdata_data_s3': {},
            },
            'storage_logdata_rta': {},
            'storage_logdata_elb': {},
        },
        'storage_algo_total': {
            'storage_algo_s3bucket': {},
            'storage_algo_public': {},
            'storage_algo_midplatform': {
                'storage_algo_midplatform_oss': {},
                'storage_algo_midplatform_s3': {},
            }
        },
        'storage_word_media_total': {
            'storage_media_total': {
                'storage_media_pl': {},
                'storage_media_image': {},
            },
            'storage_word_total': {
                'storage_word_oss': {},
                'storage_word_s3': {},
            }
        },
    },
    // 离线计算层
    'offline_calculation_total': {
        'offline_calculation_algo_total': {
            'offline_calculation_algo_model': {},
            'offline_calculation_algo_midplatform': {},
            'offline_calculation_algo_num': {},
            'offline_calculation_algo_emr': {},
        },
        'offline_calculation_engineering_total': {
            'offline_calculation_engineering_data_vg': {},
            'offline_calculation_engineering_data_cn': {},
            'offline_calculation_engineering_statement': {},
            'offline_calculation_engineering_monitor': {},
        },
        'offline_calculation_public_support_total': {
            'offline_calculation_public_support_zeppelin': {},
            'offline_calculation_public_support_azkaban': {},
            'offline_calculation_public_support_sourcedb': {},
            'offline_calculation_public_support_publicnum': {},
            'offline_calculation_public_support_offline': {},
        },
    },
    // 实时计算
    'offline_calculation_realtime': {},
    // 应用层
    'application_total': {
        'application_offline_statement_total': {
            'application_offline_statement_doris': {},
            'application_offline_statement_redshift': {},
        },
        'application_monitor_total': {
            'application_monitor_moss': {},
            'application_monitor_starrocks': {},
        },
    },
    // 消息层
    'kafka_total': {
        'kafka_log_total': {
            'kafka_log_mix': {},
            'kafka_log_hv': {},
            'kafka_log_cn': {},
        },
        'kafka_log_feature': {},
    },
}

export default tableCollection