const collection = {
    total: 594,
    ssp_tracking_total: 593,
    php_tracking_total: 555,
    adx_tracking_lb: 561,
    adx_tracking_total: 560,
    adx_tracking_compute: 559,
    adx_tracking_region: 558,
    adx_tracking_sqs: 557,
    adx_tracking_other: 556,

    ad_track_lb: 562,
    ad_track_total: 569,
    ad_track_compute: 568,
    ad_track_cache: 567,
    ad_track_region: 566,
    ad_track_az: 565,
    ad_track_sqs: 564,
    ad_track_other: 563,

    ajump_outnet: 576,
    ajump_lb: 575,
    ajump_total: 574,
    ajump_compute: 573,
    ajump_region: 572,
    ajump_az: 571,
    ajump_other: 570,

    ad_postback_outnet: 582,
    ad_postback_lb: 581,
    ad_postback_total: 580,
    ad_postback_compute: 579,
    ad_postback_region: 578,
    ad_postback_other: 577,

    roi_total: 583,

    gather_match_total: 655,
    gather_match_compute: 584,
    gather_match_outnet: 656,
    gather_match_other: 657,
    gather_tracking_total: 585,
    gather_tracking_compute: 658,
    gather_tracking_outnet: 659,
    gather_tracking_other: 660,

    sqs_total: 586,

    collect_and_match_total: 591,
    collect_and_match_compute: 590,
    collect_and_match_other: 589,

    r_tracking_total: 588,
    dynamodb_total: 587,

    demand_tracking_total: 592,

    geo_total: 443,
    treasurebox_total: 445,
}

export default collection