const modelquery = {
    collection_total: {model:48, query: 254, name: '采集层汇总'},
    collection_request_total: {model:48, query: 251, name: 'Request日志汇总'},
    collection_request_frontier: {model:48, query: 224, name: 'Frontier'},
    collection_request_ssp: {model:48, query: 221, name: 'SSP'},
    collection_request_madx: {model:48, query: 222, name: 'MADX'},
    collection_request_dsp: {model:48, query: 223, name: 'DSP'},
    collection_request_analytics: {model:48, query: 253, name: 'Analytics日志'},
    collection_request_tracking: {model:48, query: 252, name: 'Tracking日志'},
    storage_logdata_total: {model:44, query: 208, name: '原始日志与数仓汇总'},
    storage_logdata_log_total: {model:44, query: 206, name: '原始日志汇总'},
    storage_logdata_log_oss: {model:44, query: 193, name: '原始日志-OSS'},
    storage_logdata_log_s3: {model:44, query: 192, name: '原始日志-S3'},
    storage_logdata_data_total: {model:44, query: 207, name: '数仓汇总'},
    storage_logdata_data_oss: {model:44, query: 195, name: '数仓-OSS'},
    storage_logdata_data_s3: {model:44, query: 194, name: '数仓-S3'},
    storage_logdata_rta: {model:44, query: 197, name: 'RTA日志'},
    storage_logdata_elb: {model:44, query: 196, name: 'ELB日志'},
    storage_algo_total: {model:44, query: 210, name: '算法泛相关汇总'},
    storage_algo_s3bucket: {model:44, query: 198, name: 'S3Bucket'},
    storage_algo_public: {model:44, query: 201, name: '大数据公共桶'},
    storage_algo_midplatform: {model:44, query: 209, name: '数据中台汇总'},
    storage_algo_midplatform_oss: {model:44, query: 200, name: '数据中台-OSS'},
    storage_algo_midplatform_s3: {model:44, query: 199, name: '数据中台-S3'},
    storage_word_media_total: {model:44, query: 213, name: '词表与素材分发汇总'},
    storage_media_total: {model:44, query: 212, name: '素材汇总'},
    storage_media_pl: {model:44, query: 205, name: '素材-PL&EC'},
    storage_media_image: {model:44, query: 204, name: '素材-图片&视频'},
    storage_word_total: {model:44, query: 211, name: '词表汇总'},
    storage_word_oss: {model:44, query: 203, name: '词表-OSS'},
    storage_word_s3: {model:44, query: 202, name: '词表-S3'},
    offline_calculation_total: {model:45, query: 248, name: '离线计算层汇总'},
    offline_calculation_algo_total: {model:45, query: 245, name: '算法泛相关汇总'},
    offline_calculation_algo_model: {model:45, query: 229, name: '模型训练'},
    offline_calculation_algo_midplatform: {model:45, query: 231, name: '数据中台'},
    offline_calculation_algo_num: {model:45, query: 230, name: '算法跑数'},
    offline_calculation_algo_emr: {model:45, query: 232, name: 'EMR集群'},
    offline_calculation_engineering_total: {model:45, query: 246, name: '工程泛相关汇总'},
    offline_calculation_engineering_data_vg: {model:45, query: 233, name: '数仓vg'},
    offline_calculation_engineering_data_cn: {model:45, query: 234, name: '数仓cn'},
    offline_calculation_engineering_statement: {model:45, query: 235, name: '离线报表'},
    offline_calculation_engineering_monitor: {model:45, query: 236, name: '词表与业务监控'},
    offline_calculation_public_support_total: {model:45, query: 247, name: '公共支撑汇总'},
    offline_calculation_public_support_zeppelin: {model:45, query: 240, name: 'Zeppelin'},
    offline_calculation_public_support_azkaban: {model:45, query: 239, name: 'Azkaban'},
    offline_calculation_public_support_sourcedb: {model:45, query: 241, name: '元数据库'},
    offline_calculation_public_support_publicnum: {model:45, query: 238, name: '公共跑数'},
    offline_calculation_public_support_offline: {model:45, query: 237, name: '线下开发集群'},
    offline_calculation_realtime: {model:45, query: 242, name: '实时计算Flink'},
    application_total: {model:46, query: 250, name: '应用层汇总'},
    application_offline_statement_total: {model:46, query: 220, name: '业务离线报表汇总'},
    application_offline_statement_doris: {model:46, query: 217, name: 'Doris'},
    application_offline_statement_redshift: {model:46, query: 216, name: 'Redshift'},
    application_monitor_total: {model:46, query: 249, name: '实时监控与调控汇总'},
    application_monitor_moss: {model:46, query: 218, name: 'moss'},
    application_monitor_starrocks: {model:46, query: 219, name: '算法调控'},
    kafka_total: {model:47, query: 244, name: '应用层(Kafka)汇总'},
    kafka_log_total: {model:47, query: 243, name:'日志实时流汇总'},
    kafka_log_mix: {model:47, query: 225, name: '混合集群'},
    kafka_log_hv: {model:47, query: 226, name: '高价值集群'},
    kafka_log_cn: {model:47, query: 227, name: 'cn集群'},
    kafka_log_feature: {model:47, query: 228, name: '特征回流'},  
    total: {model: 'total', query: 0, name: '汇总'},
    storage_total: {model: 'storage', query: 0, name: '存储层汇总'},
}

export default modelquery