<template>
    <n-drawer :show="props.show" width="90%" placement="right" @mask-click="onMaskClick">
        <n-drawer-content title="数据表">
            <n-data-table
            :columns="useDefault ? mainColumns : dataModelColumns"
            :data="currentList"
            :bordered="false"
            size="small"
            :pagination="pagination"
            :loading="loading"
            :row-class-name="useDefault ? rowClass : dataModelRowClass"
            :scroll-x="1200"
            :cascade="false"
            allow-checking-not-loaded
            @load="onLoad">
            </n-data-table>
        </n-drawer-content>
    </n-drawer>
</template>
<style lang="less" scoped>
:deep(.child-row td) {
  background-color: #f0f5f9 !important;
}

:deep(.collection-id) {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

:deep(.row-depth) {
    width: 50px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
}
:deep(.row-depth-model) {
    width: 140px; 
    display: flex;
    justify-content: left; 
    align-items: left;
}
:deep(.depth-1) {
    width:5px; 
    height: 20px; 
    background-color: #30A9DE;
    margin-right: 2px;
}
:deep(.depth-2) {
    width:5px; 
    height: 20px; 
    background-color: #FFBC42;
    margin-right: 2px;
}
:deep(.depth-3) {
    width:5px; 
    height: 20px; 
    background-color: #9055A2;
    margin-right: 2px;
}
:deep(.depth-4) {
    width:5px; 
    height: 20px; 
    background-color: #79bd9a;
    margin-right: 2px;
}
:deep(.depth-text) {
    cursor: pointer; 
    color: #6d819c; 
    font-weight:bold
}
</style>
<script setup>
import { ref, watch, defineProps, defineEmits, onMounted, computed } from 'vue';

import { NDrawer, NDrawerContent, NDataTable } from 'naive-ui';
import dayjs from 'dayjs';
import { CollectionAPI, CostNervAPI } from '@/common/API';
import { computePreDate, computeYoyDate, convertWithoutRecursion, convertDataModelWithoutRecursion } from '@/views/Business/Analysis/tools/tool.js';

import onlineCids from '@/views/Business/Analysis/online/TableColletion.js';
import trackingCids from '@/views/Business/Analysis/tracking/TableColletion.js';
import modelCids from '@/views/Business/Analysis/datamodel/TableColletion.js';
import modelquery from '@/views/Business/Analysis/datamodel/ModelQuery.js'

// import onlineCids from '@/views/Business/Analysis/online/TableCollectionTest.js';
// import trackingCids from '@/views/Business/Analysis/tracking/TableCollectionTest.js';
// import modelCids from '@/views/Business/Analysis/datamodel/TableCollectionTest.js';
// import modelquery from '@/views/Business/Analysis/datamodel/ModelQueryTest.js'

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    tableData: {
        type: Array,
        default() {
            return [];
        },
    },
    // loading: {
    //     type: Boolean,
    //     default: false,
    // },
    root: {
        type: Array,
        default() {
            return [];
        },
    },
    useDefault: {
        type: Boolean,
        default: true,
    },
    type: {
        type: String,
        default: 'online',
    },
    granularity: {
        type: String,
        default: '',
    },
    start: {
        type: String,
        default: '',
    },
    end: {
        type: String,
        default: '',
    },
    loadRef: {
        type: Boolean,
        default: false,
    },
    startRef: {
        type: String,
        default: '',
    },
    endRef: {
        type: String,
        default: '',
    },
    dimensionList: {
        type: Array,
        default() {
            return [];
        },
    },
    curData: {
        type: Object,
        default() {
            return {};
        },
    },
});
const emits = defineEmits(['update:show', 'openCollection']);

let pagination = ref({
    pageSize: 20,
    currentPage: 1,
    total: 0,
});

let sortedTableData = ref([]);
let currentList = ref([]);

let order_current = ref('asc');
let order_yoy = ref('asc');
let order_yoy_gap = ref('asc');
let order_yoy_rate = ref('asc');
let order_pre = ref('asc');
let order_pre_gap = ref('asc');
let order_pre_rate = ref('asc');
let order_current_avg = ref('asc');
let order_ref_avg = ref('asc');
let order_avg_gap = ref('asc');
let order_avg_rate = ref('asc');

let tableObj = ref({});
let currentObj = ref({});

let yoyLoadingComplete = ref(true);
let preLoadingComplete = ref(true);
let refLoadingComplete = ref(true);

let yoyData = ref({});
let preData = ref({});
let refData = ref({});

let childrenList = ref([]);

let loading = ref(false);

let loadingComplete = computed(
    () => yoyLoadingComplete.value && preLoadingComplete.value && refLoadingComplete.value
);

const rowClass = ref( (row) => {
    if (!props.root.includes((row.collection).toString())) {
        return 'child-row';
    }
    return ''
})

const dataModelRowClass = ref( (row) => {
    if (!props.root.includes((row.key))) {
        return 'child-row';
    }
    return '';
})

const mainColumns = ref([
    // {
    //   type: "expand",
    //   expandable: (rowData) => rowData.subTableData.length !== 0,
    //   renderExpand: (rowData, rowIndex) => {
    //     return <div style="width: 80%; margin: 0 auto;"><NDataTable 
    //     data={rowData.subTableData}
    //     columns={subColumns}
    //     size="small"></NDataTable></div>;
    //   }
    // },
    {
        title: 'Collection',
        fixed: "left",
        key: 'collection',
        align: 'center',
        width: 120,
        className: 'collection-id',
        render: (row) => {
            switch (row.depth) {
                case 1:
                return <div class="row-depth"><div class="depth-1"></div><span class="depth-text" onClick={() => open(row)}>{row.collection}</span></div>;
                case 2:
                return <div class="row-depth"><div class="depth-2"></div><span class="depth-text" onClick={() => open(row)}>{row.collection}</span></div>;
                case 3:
                return <div class="row-depth"><div class="depth-3"></div><span class="depth-text" onClick={() => open(row)}>{row.collection}</span></div>;
                case 4:
                return <div class="row-depth"><div class="depth-4"></div><span class="depth-text" onClick={() => open(row)}>{row.collection}</span></div>;
                default:
                return <span style="cursor: pointer; color: #6d819c; font-weight:bold" onClick={() => open(row)}>{row.collection}</span>;
            }
            // return <span style="cursor: pointer; color: #6d819c; font-weight:bold" onClick={() => open(row)}>{row.collection}</span>;
        }
    },
    {
        title: 'Name',
        fixed: "left",
        key: 'name',
        align: 'center',
        width: 150,
        ellipsis: {
            tooltip: true
        },
    },
    {
        title (column) {
            return <div>
                <span>当前值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200;" onClick={() => {
                    order_current.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'current', order_current.value)
                }}>{order_current.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_current.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'current', order_current.value)
                }}>{order_current.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'current',
        align: 'center',
        width: 100,
    },
    {
        title (column) {
            return <div>
                <span>同比值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_yoy.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'yoy', order_yoy.value)
                }}>{order_yoy.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_yoy.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'yoy', order_yoy.value)
                }}>{order_yoy.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'yoy',
        align: 'center',
        width: 100,
    },
    {
        title (column) {
            return <div>
                <span>同比差值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_yoy_gap.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'yoy_gap', order_yoy_gap.value)
                }}>{order_yoy_gap.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_yoy_gap.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'yoy_gap', order_yoy_gap.value)
                }}>{order_yoy_gap.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'yoy_gap',
        align: 'center',
        width: 100,
        // sorter: (row1, row2) => row1.yoy_gap - row2.yoy_gap,
        render: (row) => {
                if (row.yoy_gap > 0) {
                    return <span style="color: red;">{row.yoy_gap}</span>;
                } else if (row.yoy_gap < 0) {
                    return <span style="color: green;">{row.yoy_gap}</span>;
                } else {
                    return <span>{row.yoy_gap}</span>;
                }
            },
        },
    {
        title (column) {
            return <div>
                <span>同比差值比例</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_yoy_rate.value = 'asc'
                    return currentList.value = sortRate(sortedTableData.value, 'yoy_rate', order_yoy_rate.value)
                }}>{order_yoy_rate.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_yoy_rate.value = 'desc'
                    return currentList.value = sortRate(sortedTableData.value, 'yoy_rate', order_yoy_rate.value)
                }}>{order_yoy_rate.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'yoy_rate',
        align: 'center',
        width: 120,
        // sorter: (row1, row2) => (row1.yoy_rate).replace('%','') - (row2.yoy_rate).replace('%',''),
        render: (row) => {
            if ((row.yoy_rate).replace('%','') > 0) {
                return <span style="color: red;">{row.yoy_rate}</span>;
            } else if ((row.yoy_rate).replace('%','') < 0) {
                return <span style="color: green;">{row.yoy_rate}</span>;
            } else {
                return <span>{row.yoy_rate}</span>;
            }
        },
    },
    {
        title (column) {
            return <div>
                <span>环比值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_pre.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'pre', order_pre.value)
                }}>{order_pre.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_pre.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'pre', order_pre.value)
                }}>{order_pre.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'pre',
        align: 'center',
        width: 100,
    },
    {
        title (column) {
            return <div>
                <span>环比差值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_pre_gap.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'pre_gap', order_pre_gap.value)
                }}>{order_pre_gap.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_pre_gap.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'pre_gap', order_pre_gap.value)
                }}>{order_pre_gap.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'pre_gap',
        align: 'center',
        width: 100,
        render: (row) => {
            if (row.pre_gap > 0) {
                return <span style="color: red;">{row.pre_gap}</span>;
            } else if (row.pre_gap < 0) {
                return <span style="color: green;">{row.pre_gap}</span>;
            } else {
                return <span>{row.pre_gap}</span>;
            }
        },
    },
    {
        title (column) {
            return <div>
                <span>环比差值比例</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_pre_rate.value = 'asc'
                    return currentList.value = sortRate(sortedTableData.value, 'pre_rate', order_pre_rate.value)
                }}>{order_pre_rate.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_pre_rate.value = 'desc'
                    return currentList.value = sortRate(sortedTableData.value, 'pre_rate', order_pre_rate.value)
                }}>{order_pre_rate.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'pre_rate',
        align: 'center',
        width: 120,
        render: (row) => {
            if ((row.pre_rate).replace('%','') > 0) {
                return <span style="color: red;">{row.pre_rate}</span>;
            } else if ((row.pre_rate).replace('%','') < 0) {
                return <span style="color: green;">{row.pre_rate}</span>;
            } else {
                return <span>{row.pre_rate}</span>;
            }
        },
    },
    {
        title (column) {
            return <div>
                <span>当前均值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_current_avg.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'current_avg', order_current_avg.value)
                }}>{order_current_avg.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_current_avg.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'current_avg', order_current_avg.value)
                }}>{order_current_avg.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'current_avg',
        align: 'center',
        width: 100,
    },
    {
        title (column) {
            return <div>
                <span>对照均值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_ref_avg.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'ref_avg', order_ref_avg.value)
                }}>{order_ref_avg.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_ref_avg.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'ref_avg', order_ref_avg.value)
                }}>{order_ref_avg.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'ref_avg',
        align: 'center',
        width: 100,
    },
    {
        title (column) {
            return <div>
                <span>对照数据差值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_avg_gap.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'ref_avg_gap', order_avg_gap.value)
                }}>{order_avg_gap.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_avg_gap.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'ref_avg_gap', order_avg_gap.value)
                }}>{order_avg_gap.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'ref_avg_gap',
        align: 'center',
        width: 120,
        render: (row) => {
            if (row.ref_avg_gap > 0) {
                return <span style="color: red;">{row.ref_avg_gap}</span>;
            } else if (row.ref_avg_gap < 0) {
                return <span style="color: green;">{row.ref_avg_gap}</span>;
            } else {
                return <span>{row.ref_avg_gap}</span>;
            }
        },
    },
    {
        title (column) {
            return <div>
                <span>对照差值比例</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_avg_rate.value = 'asc'
                    return currentList.value = sortRate(sortedTableData.value, 'ref_avg_rate', order_avg_rate.value)
                }}>{order_avg_rate.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_avg_rate.value = 'desc'
                    return currentList.value = sortRate(sortedTableData.value, 'ref_avg_rate', order_avg_rate.value)
                }}>{order_avg_rate.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'ref_avg_rate',
        align: 'center',
        width: 120,
        render: (row) => {
            if ((row.ref_avg_rate).replace('%','') > 0) {
                return <span style="color: red;">{row.ref_avg_rate}</span>;
            } else if ((row.ref_avg_rate).replace('%','') < 0) {
                return <span style="color: green;">{row.ref_avg_rate}</span>;
            } else {
                return <span>{row.ref_avg_rate}</span>;
            }
        },
    },
]);

const dataModelColumns = ref([
    {
        title: 'Name',
        fixed: "left",
        key: 'name',
        align: 'left',
        width: 240,
        ellipsis: {
            tooltip: true
        },
        render: (row) => {
            switch (row.depth) {
                case 1:
                return <div class="row-depth-model"><div class="depth-1"></div><span>{row.name}</span></div>;
                case 2:
                return <div class="row-depth-model"><div class="depth-2"></div><span>{row.name}</span></div>;
                case 3:
                return <div class="row-depth-model"><div class="depth-3"></div><span>{row.name}</span></div>;
                case 4:
                return <div class="row-depth-model"><div class="depth-4"></div><span>{row.name}</span></div>;
                default:
                return <span>{row.name}</span>;
            }
        }
    },
    {
        title (column) {
            return <div>
                <span>当前值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200;" onClick={() => {
                    order_current.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'current', order_current.value)
                }}>{order_current.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_current.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'current', order_current.value)
                }}>{order_current.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'current',
        align: 'center',
        width: 100,
    },
    {
        title (column) {
            return <div>
                <span>同比值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_yoy.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'yoy', order_yoy.value)
                }}>{order_yoy.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_yoy.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'yoy', order_yoy.value)
                }}>{order_yoy.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'yoy',
        align: 'center',
        width: 100,
    },
    {
        title (column) {
            return <div>
                <span>同比差值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_yoy_gap.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'yoy_gap', order_yoy_gap.value)
                }}>{order_yoy_gap.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_yoy_gap.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'yoy_gap', order_yoy_gap.value)
                }}>{order_yoy_gap.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'yoy_gap',
        align: 'center',
        width: 100,
        // sorter: (row1, row2) => row1.yoy_gap - row2.yoy_gap,
        render: (row) => {
                if (row.yoy_gap > 0) {
                    return <span style="color: red;">{row.yoy_gap}</span>;
                } else if (row.yoy_gap < 0) {
                    return <span style="color: green;">{row.yoy_gap}</span>;
                } else {
                    return <span>{row.yoy_gap}</span>;
                }
            },
        },
    {
        title (column) {
            return <div>
                <span>同比差值比例</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_yoy_rate.value = 'asc'
                    return currentList.value = sortRate(sortedTableData.value, 'yoy_rate', order_yoy_rate.value)
                }}>{order_yoy_rate.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_yoy_rate.value = 'desc'
                    return currentList.value = sortRate(sortedTableData.value, 'yoy_rate', order_yoy_rate.value)
                }}>{order_yoy_rate.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'yoy_rate',
        align: 'center',
        width: 120,
        // sorter: (row1, row2) => (row1.yoy_rate).replace('%','') - (row2.yoy_rate).replace('%',''),
        render: (row) => {
            if ((row.yoy_rate).replace('%','') > 0) {
                return <span style="color: red;">{row.yoy_rate}</span>;
            } else if ((row.yoy_rate).replace('%','') < 0) {
                return <span style="color: green;">{row.yoy_rate}</span>;
            } else {
                return <span>{row.yoy_rate}</span>;
            }
        },
    },
    {
        title (column) {
            return <div>
                <span>环比值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_pre.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'pre', order_pre.value)
                }}>{order_pre.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_pre.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'pre', order_pre.value)
                }}>{order_pre.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'pre',
        align: 'center',
        width: 100,
    },
    {
        title (column) {
            return <div>
                <span>环比差值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_pre_gap.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'pre_gap', order_pre_gap.value)
                }}>{order_pre_gap.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_pre_gap.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'pre_gap', order_pre_gap.value)
                }}>{order_pre_gap.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'pre_gap',
        align: 'center',
        width: 100,
        render: (row) => {
            if (row.pre_gap > 0) {
                return <span style="color: red;">{row.pre_gap}</span>;
            } else if (row.pre_gap < 0) {
                return <span style="color: green;">{row.pre_gap}</span>;
            } else {
                return <span>{row.pre_gap}</span>;
            }
        },
    },
    {
        title (column) {
            return <div>
                <span>环比差值比例</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_pre_rate.value = 'asc'
                    return currentList.value = sortRate(sortedTableData.value, 'pre_rate', order_pre_rate.value)
                }}>{order_pre_rate.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_pre_rate.value = 'desc'
                    return currentList.value = sortRate(sortedTableData.value, 'pre_rate', order_pre_rate.value)
                }}>{order_pre_rate.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'pre_rate',
        align: 'center',
        width: 120,
        render: (row) => {
            if ((row.pre_rate).replace('%','') > 0) {
                return <span style="color: red;">{row.pre_rate}</span>;
            } else if ((row.pre_rate).replace('%','') < 0) {
                return <span style="color: green;">{row.pre_rate}</span>;
            } else {
                return <span>{row.pre_rate}</span>;
            }
        },
    },
    {
        title (column) {
            return <div>
                <span>当前均值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_current_avg.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'current_avg', order_current_avg.value)
                }}>{order_current_avg.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_current_avg.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'current_avg', order_current_avg.value)
                }}>{order_current_avg.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'current_avg',
        align: 'center',
        width: 100,
    },
    {
        title (column) {
            return <div>
                <span>对照均值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_ref_avg.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'ref_avg', order_ref_avg.value)
                }}>{order_ref_avg.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_ref_avg.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'ref_avg', order_ref_avg.value)
                }}>{order_ref_avg.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'ref_avg',
        align: 'center',
        width: 100,
    },
    {
        title (column) {
            return <div>
                <span>对照数据差值</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_avg_gap.value = 'asc'
                    return currentList.value = sortValue(sortedTableData.value, 'ref_avg_gap', order_avg_gap.value)
                }}>{order_avg_gap.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_avg_gap.value = 'desc'
                    return currentList.value = sortValue(sortedTableData.value, 'ref_avg_gap', order_avg_gap.value)
                }}>{order_avg_gap.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'ref_avg_gap',
        align: 'center',
        width: 120,
        render: (row) => {
            if (row.ref_avg_gap > 0) {
                return <span style="color: red;">{row.ref_avg_gap}</span>;
            } else if (row.ref_avg_gap < 0) {
                return <span style="color: green;">{row.ref_avg_gap}</span>;
            } else {
                return <span>{row.ref_avg_gap}</span>;
            }
        },
    },
    {
        title (column) {
            return <div>
                <span>对照差值比例</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_avg_rate.value = 'asc'
                    return currentList.value = sortRate(sortedTableData.value, 'ref_avg_rate', order_avg_rate.value)
                }}>{order_avg_rate.value === 'desc' ? '↓' : ''}</span>
                <span style="float: right; cursor: pointer; color: #6d819c; font-weight: 200" onClick={() => {
                    order_avg_rate.value = 'desc'
                    return currentList.value = sortRate(sortedTableData.value, 'ref_avg_rate', order_avg_rate.value)
                }}>{order_avg_rate.value === 'asc' ? '↑' : ''}</span>
            </div>;
        },
        key: 'ref_avg_rate',
        align: 'center',
        width: 120,
        render: (row) => {
            if ((row.ref_avg_rate).replace('%','') > 0) {
                return <span style="color: red;">{row.ref_avg_rate}</span>;
            } else if ((row.ref_avg_rate).replace('%','') < 0) {
                return <span style="color: green;">{row.ref_avg_rate}</span>;
            } else {
                return <span>{row.ref_avg_rate}</span>;
            }
        },
    },
]);

function onMaskClick() {
    emits('update:show', false);
}
function open(row) {
    const collection = {
        id: row.collection,
        name: row.name,
        admin: row.admin,
        feFilterState: row.feFilterState,
        tags: row.tags,
        teamgroups: row.teamgroups,
    };
    emits('openCollection', collection);
}

function sortValue(list, column, order) {
    list.sort((a, b) => (order === "asc" ? parseFloat(a[column]) - parseFloat(b[column]) : parseFloat(b[column]) - parseFloat(a[column])));

    for (const item of list) {
        if (item.children && item.children.length > 0) {
            item.children = sortValue(item.children, column, order);
        }
    }
    return list;
}

function sortRate(list, column, order) {
  list.sort((a, b) => (order === "asc" ? parseFloat((a[column]).replace('%','')) - parseFloat((b[column]).replace('%','')) : parseFloat((b[column]).replace('%','')) - parseFloat((a[column]).replace('%',''))));

  for (const item of list) {
    if (item.children && item.children.length > 0) {
      item.children = sortRate(item.children, column, order);
    }
  }
  return list;
}

function findObject(collection, targetKey, depth = 1) {
    if (typeof collection !== 'object' || Object.keys(collection).length === 0) {
        return null;
    }
    
    if (collection.hasOwnProperty(targetKey)) {
        return [collection[targetKey], depth];
    }

    for (const key in collection) {
        const result = findObject(collection[key], targetKey, depth + 1);
        if (result !== null) {
            return result;
        }
    }

    return null;
}

function onLoad(row) {
    loading.value = true;
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (loadingComplete.value) {
                clearInterval(interval);
                if (props.useDefault) {
                    onClick(row)
                } else {
                    onClickDataModel(row)
                }
                resolve();
            }
        }, 100);
    });
}

function onClickDataModel(row) {
    const r = findObject(tableObj.value, row.key)
    currentObj.value = r[0]
    const depth = r[1]
    childrenList.value = Object.keys(currentObj.value);
    const preDate = [props.start - 1 * 24 * 60 * 60, props.end - 1 * 24 * 60 * 60]
    const yoyDate = [props.start - 7 * 24 * 60 * 60, props.end - 7 * 24 * 60 * 60]
    loadDataModel(childrenList.value, preDate[0], preDate[1], 'pre');
    loadDataModel(childrenList.value, yoyDate[0], yoyDate[1], 'yoy');
    if (props.loadRef) {
        loadDataModel(childrenList.value, props.startRef, props.endRef, 'ref');
    }
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (loadingComplete.value) {
                clearInterval(interval);
                row.children = convertDataModelWithoutRecursion(currentObj.value, childrenList.value, props.curData, yoyData.value, preData.value, refData.value, depth);
                loading.value = false;
                resolve();
            }
        }, 500);
    });
}

function onClick(row) {
    const r = findObject(tableObj.value, parseInt(row.collection))
    currentObj.value = r[0]
    const depth = r[1]
    childrenList.value = Object.keys(currentObj.value);
    const preDate = computePreDate(props.granularity, props.start, props.end);
    const yoyDate = computeYoyDate(props.granularity, props.start, props.end);
    load(childrenList.value, preDate[0], preDate[1], 'pre');
    load(childrenList.value, yoyDate[0], yoyDate[1], 'yoy');
    if (props.loadRef) {
        load(childrenList.value, props.startRef, props.endRef, 'ref');
    }

    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (loadingComplete.value) {
                clearInterval(interval);
                row.children = convertWithoutRecursion(currentObj.value, childrenList.value, props.curData, yoyData.value, preData.value, refData.value, depth);
                loading.value = false;
                resolve();
            }
        }, 500);
    });
}

function loadDataModel(list, startVal, endVal, tag) {
    switch (tag) {
        case 'yoy':
            yoyData.value = {}
            yoyLoadingComplete.value = false;
            break;
        case 'pre':
            preData.value = {}
            preLoadingComplete.value = false;
            break;
        case 'ref':
            refData.value = {}
            refLoadingComplete.value = false;
            break;
        default:
            break;
    }    
    
    for (const idx in list) {
        const item = list[idx]
        CostNervAPI.calculateQuery(
            modelquery[item].model,
            modelquery[item].query,
            startVal,
            endVal,
        )
            .then(res => {
                let avg = 0.0;
                let sum = 0.0
                let end_date = (new Date('2000-01-01 00:00:00')).getTime() / 1000
                let end_value = 0.0
                
                const obj = Object.values(res.data.values)[0]
                const len = Object.keys(obj).length;
                
                for (const key in obj) {
                    sum += obj[key];
                    if (end_date < key) {
                        end_date = key
                    }
                }
                avg = (sum / len).toFixed(2);
                end_value = obj[end_date]

                if (tag === 'yoy') {
                    yoyData.value[item] = {
                        id: item,
                        name: modelquery[item].name,
                        model_id: modelquery[item].model,
                        query_id: modelquery[item].query,
                        value: parseFloat(end_value),
                    };
                } else if (tag === 'pre') {
                    preData.value[item] = {
                        id: item,
                        name: modelquery[item].name,
                        model_id: modelquery[item].model,
                        query_id: modelquery[item].query,
                        value: parseFloat(end_value),
                    };
                } else if (tag === 'ref') {
                    refData.value[item] = {
                        id: item,
                        name: modelquery[item].name,
                        model_id: modelquery[item].model,
                        query_id: modelquery[item].query,
                        value: parseFloat(avg),
                    };
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
};

function load(list, startVal, endVal, tag) {
    switch (tag) {
        case 'yoy':
            yoyData.value = {}
            yoyLoadingComplete.value = false;
            break;
        case 'pre':
            preData.value = {}
            preLoadingComplete.value = false;
            break;
        case 'ref':
            refData.value = {}
            refLoadingComplete.value = false;
            break;
        default:
            break;
    }
    for (const idx in list) {
        const cid = parseInt(list[idx])
        CollectionAPI.getCostDataByCollectionIdM(
            cid,
            startVal,
            endVal,
            props.granularity,
            props.dimensionList,
        )
            .then(res => {
                let end_date = new Date('2000-01-01 00:00:00')
                let end_value = 0.0
                let avg = 0.0
                for (const key in res.data) {
                    if (props.dimensionList.length === 1) {
                        if (props.dimensionList[0] === 'all') {
                            if (key === 'MV') {
                                let sum = 0.0;
                                const len = Object.keys(res.data[key]).length;
                                for (const d in res.data[key]) {
                                    sum += res.data[key][d];
                                    if (end_date < new Date(d)) {
                                        end_date = new Date(d)
                                    }
                                }
                                avg = (sum / len).toFixed(2);
                                end_value = res.data[key][dayjs(end_date).format('YYYY-MM-DD HH:mm:ss')]
                            }
                        } else {
                            if (key === item.value) {
                                let sum = 0.0;
                                const len = Object.keys(res.data[key]).length;
                                for (const d in res.data[key]) {
                                    sum += res.data[key][d];
                                    if (end_date < new Date(d)) {
                                        end_date = new Date(d)
                                    }
                                }
                                avg = (sum / len).toFixed(2);
                                end_value = res.data[key][dayjs(end_date).format('YYYY-MM-DD HH:mm:ss')]
                            }
                        }
                    } else {
                        const cloudregion = cloud.value+':'+region.value
                        if (key === cloudregion) {
                            let sum = 0.0;
                            const len = Object.keys(res.data[key]).length;
                            for (const d in res.data[key]) {
                                sum += res.data[key][d];
                                if (end_date < new Date(d)) {
                                    end_date = new Date(d)
                                }
                            }
                            avg = (sum / len).toFixed(2);
                            end_value = res.data[key][dayjs(end_date).format('YYYY-MM-DD HH:mm:ss')]
                        }
                    }
                }

                if (tag === 'yoy') {
                    yoyData.value[cid] = {
                        id: cid,
                        value: end_value,
                        name: res.data.query.collection_name,
                    };
                } else if (tag === 'pre') {
                    preData.value[cid] = {
                        id: cid,
                        value: end_value,
                        name: res.data.query.collection_name,
                    };
                } else if (tag === 'ref') {
                    refData.value[cid] = {
                        id: cid,
                        value: avg,
                        name: res.data.query.collection_name,
                    };
                }

            })
            .catch(err => {
                console.log(err);
            });
    }
};

onMounted(() => {
    if (props.type === 'online') {
        tableObj.value = onlineCids;
    } else if (props.type === 'tracking') {
        tableObj.value = trackingCids;
    } else if (props.type === 'model') {
        tableObj.value = modelCids;
    } else {
        tableObj.value = onlineCids;
    }

    currentObj.value = tableObj.value;
});

watch (() => yoyData.value,
    () => {
        if (Object.keys(yoyData.value).length === childrenList.value.length) {
            yoyLoadingComplete.value = true;
        }
    },
    { deep: true }
);

watch (() => preData.value,
    () => {
        if (Object.keys(preData.value).length === childrenList.value.length) {
            preLoadingComplete.value = true;
        }
    },
    { deep: true }
);

watch (() => refData.value,
    () => {
        if (Object.keys(refData.value).length === childrenList.value.length) {
            refLoadingComplete.value = true;
        }
    },
    { deep: true }
);

watch(() => props.tableData, (newVal, oldVal) => {
    sortedTableData.value = newVal;
    pagination.value.total = newVal.length;
    pagination.value.currentPage = 1;

    currentList.value = sortedTableData.value
});
</script>