<template>
    <div class="chart-box">
        <div class="header-bar">
            <div class="chart-name">{{ name }}</div>
            <div class="header-plugin">
                <slot name="header"></slot>
            </div>
        </div>
        <div
            ref="chartDOM"
            class="chart-panel"
            :style="{ backgroundColor }"
        ></div>
        <div v-show="loading" class="loading">
            <n-spin>
                <div style="margin-top: 160px; width: 100%; height: 100%"></div>
            </n-spin>
        </div>
        <slot name="footer"></slot>
    </div>
</template>

<style lang="less" scoped>
@import '../common/common.less';

.chart-box {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: @fill-color;

    .header-bar {
        border-left: 3px solid @label-color;
        height: 30px;
        line-height: 30px;
        margin: 12px 0;
        padding: 0 12px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .chart-name {
            font-weight: bold;
            font-size: 16px;
        }

        .header-plugin {
            margin-left: auto;
        }
    }

    .chart-panel {
        flex-grow: 1;
        width: 100%;
        overflow: hidden;
    }

    .loading {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #ffffff66;
    }
}
</style>

<script setup>
import { ref, onMounted, onBeforeUnmount, nextTick } from 'vue';

import { NSpin } from 'naive-ui';

const chartDOM = ref();

const props = defineProps({
    name: {
        type: String,
        default: 'NONAME',
    },
    backgroundColor: {
        type: String,
        default: '#fff',
    },
    loading: {
        type: Boolean,
        default: false,
    },
});

function getChartDOM() {
    return chartDOM.value;
}

const event = defineEmits(['resize', 'mounted']);

defineExpose({
    getChartDOM,
});

let isFirstResize = true;

const resizeOb = new ResizeObserver(entries => {
    if (isFirstResize) {
        isFirstResize = false;
        return;
    }
    let { width, height } = entries[0].contentRect;
    event('resize', { width, height }, chartDOM.value);
});

onMounted(() => {
    event('mounted', chartDOM.value);
    nextTick(() => {
        resizeOb.observe(chartDOM.value);
    });
});

onBeforeUnmount(() => {
    resizeOb.unobserve(chartDOM.value);
});
</script>
