/**
 * @file Entry.js
 * @description Traffic Trading Konva App Entry
 */

import Konva from 'konva';

import bgSrc from './assets/bg.png';

import drawDataLabels from './DataLabels';

export default class KonvaApp {
    container = null;
    stage = null;
    labelsControl = null;
    // 画布尺寸发生变化导致图形重绘时，需要将外部传入数据也带入绘制流程中
    dataSourceCopy = null;
    labelClickListener = null;

    constructor(containerId, options = {}) {
        this.container = document.getElementById(containerId);
        this.stage = new Konva.Stage({
            container: containerId,
            width: options.width || this.container.clientWidth,
            height: options.height || this.container.clientHeight,
        });
        this.draw();
    }

    draw() {
        drawBackground(this.stage);
        this.labelsControl = drawDataLabels(this.stage);
    }

    refresh() {
        this.stage.destroyChildren();
        this.draw();
        this.setData(this.dataSourceCopy);
        this.labelsControl.onLabelClick(this.labelClickListener);
    }

    setSize(width, height) {
        this.stage.width(width);
        this.stage.height(height);
        this.refresh();
    }

    setData(dataSource) {
        this.dataSourceCopy = dataSource;
        this.labelsControl.setData(dataSource);
    }

    setClickHandler(callback) {
        this.labelClickListener = callback;
        this.labelsControl.onLabelClick(this.labelClickListener);
    }
}

function drawBackground(stage) {
    let layer = new Konva.Layer();
    let img = new Image();
    img.src = bgSrc;
    layer.add(
        new Konva.Image({
            x: 0,
            y: 0,
            image: img,
            width: stage.width(),
            height: stage.height(),
        })
    );
    stage.add(layer);
    return layer;
}
