// const tableCollection = {
//     594: [],
//     593: [],
//     555: [],
//     560: [561, 559, 558, 557, 556],
//     592: [],
//     569: [562, 568, 567, 566, 565, 564, 563],
//     574: [576, 575, 573, 572, 571, 570],
//     580: [582, 581, 579, 578, 577],
//     583: [],
//     655: [584, 656, 657],
//     585: [658, 659, 660],
//     586: [],
//     591: [590, 589],
//     588: [],
//     587: [],
//     443: [],
//     445: [],
// }

const tableCollection = {
    594: {},
    593: {555: {},
        560: {561:{}, 559:{}, 558:{}, 557:{}, 556:{}}
    },
    592: {
        569: {562:{}, 568:{}, 567:{}, 566:{}, 565:{}, 564:{}, 563:{}},
        574: {576:{}, 575:{}, 573:{}, 572:{}, 571:{}, 570:{}},
        580: {582:{}, 581:{}, 579:{}, 578:{}, 577:{}},
        583: {},
        655: {584:{}, 656:{}, 657:{}},
        585: {658:{}, 659:{}, 660:{}},
        586: {},
        591: {590:{}, 589:{}},
        588: {},
        587: {},
    },
    443: {},
    445: {},
}

export default tableCollection