<template>
    <div class="cost-chart">
        <chart-box
            :name="props.title"
            :loading="loading"
            @mounted="renderChart"
        >
        <template #header>
            <n-button
                size="tiny"
                style="margin-top: 5px"
                @click="exportCSV"
            >
                导出CSV
            </n-button>
        </template>
        </chart-box>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';
.cost-chart {
    height: 330px;
    .standard-border;
    .standard-shadow;
}
</style>

<script setup>
import { ref, defineProps, watch } from 'vue';
import { useMessage, NButton } from 'naive-ui';
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { AdvancedTooltip, yAxisLabelFormatter } from '@/common/EChartsTools';
import { formatNumber, formatAxisData, isEmptyObject } from '@/common/tools';
import { aliasRegionLabel } from './tools';

import ChartBox from '@/components/ChartBox';

import { CollectionAPI } from '@/common/API';

const props = defineProps(['id', 'dimension', 'params', 'title']);

const message = useMessage();

watch(
    () => props.params,
    value => {
        loadData();
    }
);

echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    CanvasRenderer,
    LabelLayout,
    GridComponent,
    LineChart,
    UniversalTransition,
]);

let echartInstance = null;

let aTooltip = new AdvancedTooltip();

function renderChart(dom) {
    echartInstance = echarts.init(dom);
}

let costData = null;

let loading = ref(false);

let csvContent = ref('');

function exportCSV(){
    let blob = new Blob([csvContent.value], { type: "text/csv;charset=utf-8;" });

    let link = document.createElement("a");
    if (link.download !== undefined) {
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "export.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        alert("Your browser does not support downloading files.");
    }
};

function loadData() {
    loading.value = true;
    echartInstance && echartInstance.clear();
    CollectionAPI.getCostDataByCollectionId(
        props.id,
        props.params.dateRange[0],
        props.params.dateRange[1],
        props.params.granularity,
        props.dimension
    )
        .then(res => {
            loading.value = false;
            costData = res.data;
            delete costData.query;
            setChartOption();
            handleCSVData();
        })
        .catch(err => {
            console.log(err);
            message.error('未知异常导致加载失败');
        });
}

function setChartOption() {
    let resData = costData;
    if (isEmptyObject(resData)) {
        return;
    }
    let legends = Object.keys(resData);
    let axisData = Object.keys(resData[legends[0]]);
    echartInstance.setOption({
        grid: {
            top: 40,
            left: 56,
            right: 140,
            bottom: 30,
        },
        tooltip: aTooltip.tooltip({
            valueFormatter: value => formatNumber(value),
        }),
        legend: {
            show: true,
            type: 'scroll',
            data: legends.map(key =>
                props.dimension === 'region' ? aliasRegionLabel(key) : key
            ),
            orient: 'vertical',
            right: 20,
            top: 'middle',
            textStyle: {
                width: 80,
                overflow: 'breakAll',
            },
            selector: [
                {
                    // 全选
                    type: 'all',
                    // 可以是任意你喜欢的标题
                    title: '全选',
                },
                {
                    // 反选
                    type: 'inverse',
                    // 可以是任意你喜欢的标题
                    title: '反选',
                },
            ],
        },
        xAxis: {
            type: 'category',
            data: formatAxisData(axisData, props.params.granularity),
        },
        yAxis: {
            type: 'value',
            ...yAxisLabelFormatter(),
        },
        series: legends.map(key => ({
            name: props.dimension === 'region' ? aliasRegionLabel(key) : key,
            type: 'line',
            showSymbol: false,
            data: axisData.map(
                date => resData[key][date] && +resData[key][date].toFixed(2)
            ),
            triggerLineEvent: true,
        })),
    });
}

function handleCSVData() {
    csvContent.value = "";
    csvContent.value += "name";
    let column = []
    for (const key in costData) {
        for (const item in costData[key]) {
            let temp = item.split(' ')[0];
            if (!column.includes(temp)) {
                column.push(temp);
            }
        }
    }
    for (const key in column) {
        csvContent.value += "," + column[key];
    }
    csvContent.value += "\n";
    for (const key in costData) {
        csvContent.value += key;
        for (const idx in column) {
            let temp = column[idx]+' 00:00:00'
            if (costData[key][temp]) {
                csvContent.value += "," + costData[key][temp];
            } else {
                csvContent.value += "," + '/';
            }
        }
        csvContent.value += "\n";
    }
};

loadData();
</script>
