const tableCollection = {
    // 均值
    554: {},
    // 出网
    621: {
        287: {},
        286: {},
        640: {},
        650: {},
        646: {},
        296: {},
        717: {},
        722: {},
        603: {},
        608: {},
        613: {},
    },
    // 流量接入层
    626: {
        285: {
            284: {},
            283: {},
        },
        602: {
            601: {},
            600: {},
            599: {},
            598: {},
        },
        607: {
            605: {},
            606: {},
            604: {},
        },
        612: {
            610: {},
            611: {},
            609: {},
        },
        282: {
            279: {},
            280: {},
            281: {},
        },
        358: {},
        644: {
            641: {},
            642: {},
            643: {},
        },
        645: {
            647: {},
            648: {},
            649: {},
        },
    },
    // Exchange
    627: {
        278: {
            275: {},
            276: {},
            277: {},
        },
        729: {
            728: {},
            727: {},
            726: {},
        },
        725: {
            719: {
                716: {},
                715: {},
            },
            724: {
                721: {},
                720: {},
            },
        },
        730: {
            718: {},
            723: {},
        },
        274: {
            270: {},
            272: {},
            273: {},
        },
        303: {
            295: {
                293: {},
                294: {},
            },
            302: {
                298: {},
                299: {},
            },
            504: {
                297: {},
                301: {},
            },
        },
        307: {
            394: {
                393: {},
                639: {},
                392: {},
                391: {},
            },
            398: {
                397: {},
                638: {},
                396: {},
                395: {},
            },
        },
        406: {
            742: {
                737: {
                    736: {},
                    735: {},
                    734: {},
                },
                741: {
                    740: {},
                    739: {},
                    738: {},
                },
            },
            751: {
                746: {
                    745: {},
                    744: {},
                    743: {},
                },
                750: {
                    749: {},
                    748: {},
                    747: {},
                },
            },
            772: {
                401: {
                    400: {},
                    399: {},
                    310: {},
                },
                405: {
                    404: {},
                    403: {},
                    402: {},
                },
            },
        },
        771: {
            769: {},
            770: {},
        },
    },
    // DSP
    628: {
        676: {
            675: {
                671: {
                    670: {},
                    669: {},
                },
                674: {
                    673: {},
                    672: {},
                },
            },
            668: {
                664: {
                    663: {},
                    662: {},
                },
                667: {
                    666: {},
                    665: {},
                },
            },
        },
        620: {
            616: {
                615: {},
                614: {},
            },
            619: {
                618: {},
                617: {},
            },
        },
        693: {
            695: {
                688: {
                    687: {},
                    686: {},
                    685: {},
                },
                692: {
                    691: {},
                    690: {},
                    689: {},
                },
            },
            694: {
                680: {
                    679: {},
                    678: {},
                    677: {},
                },
                684: {
                    683: {},
                    682: {},
                    681: {},
                },
            },
            422: {
                417: {
                    416: {},
                    414: {},
                },
                421: {
                    420: {},
                    418: {},
                },
            },
        },
        370: {
            381: {
                375: {
                    371: {},
                    372: {},
                    373: {},
                    374: {},
                },
                380: {
                    376: {},
                    377: {},
                    378: {},
                    379: {},
                },
            },
            425: {
                386: {
                    385: {},
                    383: {},
                    384: {},
                    426: {},
                },
                427: {
                    387: {},
                    423: {},
                    424: {},
                    389: {},
                },
            },
        },
    },
    // 流量侧公共层
    630: {
        323: {
            321: {},
            322: {},
        },
        335: {},
        596: {
            635: {},
            636: {},
            637: {},
        },
        436: {
            431: {
                430: {},
                429: {},
                428: {},
            },
            435: {
                434: {},
                433: {},
                432: {},
            },
        },
        443: {
            438: {},
            442: {
                441: {},
                440: {},
                439: {},
            },
        },
        445: {
            444: {},
            332: {},
        },
        654: {
            653: {},
            652: {},
        },
        469: {
            453: {
                448: {
                    447: {},
                    446: {},
                    458: {},
                },
                452: {
                    451: {},
                    450: {},
                    449: {},
                },
            },
            456: {
                455: {},
                457: {},
                501: {},
            },
            467: {
                466: {},
                465: {},
                464: {},
            },
            462: {
                459: {},
                460: {},
                461: {},
            },
            468: {},
        },
        787: {
            785: {},
            786: {},
        },
    },
    // DSP公共层
    629: {
        478: {
            473: {
                472: {},
                471: {},
                470: {},
            },
            477: {
                476: {},
                475: {},
                474: {},
            },
        },
        597: {
            631: {},
            632: {},
            633: {},
            634: {},
        },
        489: {
            480: {},
            484: {
                483: {},
                482: {},
                481: {},
            },
            488: {
                487: {},
                486: {},
                485: {},
            },
        },
        553: {
            535: {
                531: {
                    530: {},
                    529: {},
                },
                534: {
                    533: {},
                    532: {},
                },
            },
            542: {
                538: {
                    537: {},
                    536: {},
                },
                541: {
                    540: {},
                    539: {},
                },
            },
            552: {
                548: {
                    547: {},
                    546: {},
                },
                551: {
                    550: {},
                    549: {},
                },
            },
            545: {
                544: {},
                543: {},
            },
        },
        528: {
            527: {
                523: {
                    522: {},
                    521: {},
                },
                526: {
                    525: {},
                    524: {},
                },
            },
            517: {
                513: {
                    512: {},
                    511: {},
                },
                516: {
                    515: {},
                    514: {},
                },
            },
            520: {
                519: {},
                518: {},
            },
        },
        756: {
            755: {},
            757: {},
            754: {},
            753: {},
        },
        768: {
            767: {
                766: {},
                765: {},
                764: {},
                763: {},
            },
            762: {
                761: {},
                760: {},
                759: {},
                758: {},
            },
        },
    },
    // cdn
    752: {},
}

export default tableCollection