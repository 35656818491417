<template>
    <div class="ctrl-bar fixed">
        <div class="label">时间：</div>
        <f-date-picker
            class="input"
            style="width: 120px"
            v-model:date="dateRes"
            :is-date-disabled="disableDate"
            @update:date="onQueryChangeDate"
        ></f-date-picker>
        <n-radio-group
            v-if="showGranularity"
            v-model:value="granularity"
            name="granularity"
            size="small"
            class="input"
            @update:value="onQueryChangeGranularity"
        >
            <n-radio-button label="日" value="day"></n-radio-button>
            <n-radio-button label="周" value="week"></n-radio-button>
            <n-radio-button label="相对周" value="7"></n-radio-button>
            <n-radio-button label="月" value="month"></n-radio-button>
        </n-radio-group>
        <div class="dimension" v-if="showDimension">
            <div class="label">云商: </div>
            <n-select
                v-model:value="cloudFilter"
                filterable
                tag
                size="small"
                :options="cloudList"
                style="width: 120px"
                class="input"
                :consistent-menu-width="false"
                @update:value="updateCloudFilter"
            ></n-select>
        </div>
        <div class="dimension" v-if="showDimension">
            <div class="label">地域: </div>
            <n-select
                v-model:value="regionFilter"
                filterable
                tag
                size="small"
                :options="regionList"
                style="width: 120px"
                class="input"
                :consistent-menu-width="false"
                @update:value="updateRegionFilter"
            ></n-select>
        </div>
        <div class="label">对照时间：</div>
        <f-date-picker
            class="input"
            style="width: 120px"
            v-model:date="dateResRef"
            :is-date-disabled="disableDate"
            placeholder="可选"
            :clearable="true"
            @update:date="onQueryChangeRefDate"
        ></f-date-picker>
        <n-button
            size="small"
            class="input"
            secondary
            type="primary"
            @click="onQueryResult"
        >
            <template #icon>
                <n-icon>
                    <SearchFilled />
                </n-icon>
            </template>
            查询
        </n-button>
        <n-button :loading="btnLoading" type="info" class="submit" size="small" @click="showCollectionTable">
            数据表
        </n-button>
    </div>
</template>
<style lang="less" scoped>
@import '../../../../common/common.less';
.ctrl-bar {
    .common-ctrl-bar;
}
.fixed {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
}
.dimension {
    display: flex;
    align-items: center;
}
</style>
<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import FDatePicker from '@/components/FinOpsDatePicker';
import dayjs from 'dayjs';
import { NRadioGroup, NRadioButton, NSelect, NButton, NIcon } from 'naive-ui';
import { SearchFilled } from '@vicons/material';
import regionMap from '@/common/regionMap.json';

const route = useRoute();
const router = useRouter();

const emits = defineEmits(['update:cloud', 'update:region', 'update:granularity', 'update:date', 'update:refDate', 'update:dimension', 'showTable', 'onQuery']);
const props = defineProps({
    btnLoading: {
        type: Boolean,
        default: true,
    },
    showDimension: {
        type: Boolean,
        default: true,
    },
    showGranularity: {
        type: Boolean,
        default: true,
    },
});

let dateRes = ref(route.query.start ? { range: [(new Date(route.query.start)).getTime(), (new Date(route.query.end)).getTime()] } : { type: 'last7' });
let granularity = ref(route.query.granularity || 'day');
let start = ref(route.query.start || dayjs().subtract(8, 'day').format('YYYY-MM-DD'));
let end = ref(route.query.end || dayjs().subtract(2, 'day').format('YYYY-MM-DD'));

let dateResRef = ref(route.query.startRef ? { range: [(new Date(route.query.startRef)).getTime(), (new Date(route.query.endRef)).getTime()] } :{});
let startRef = ref(route.query.startRef || '');
let endRef = ref(route.query.endRef || '');

let cloudFilter = ref(route.query.cloud || 'all');
let cloudList = ref([
    {
        label: '全部',
        value: 'all',
    },
    {
        label: 'aws',
        value: 'aws',
    },
    {
        label: 'aliyun',
        value: 'aliyun',
    },
    {
        label: 'gcp',
        value: 'gcp',
    },
    {
        label: 'wangsu',
        value: 'wangsu',
    },
    {
        label: 'huawei',
        value: 'huawei',
    },
]);

let regionFilter = ref(route.query.region || 'all');
let regionList = ref([
    {
        label: '全部',
        value: 'all',
    },
]);

let dimensionList = ref({
    cloud: route.query.cloud  || 'all',
    region: route.query.region || 'all',
})

function showCollectionTable() {
    emits('showTable')
};

function disableDate(value) {
    return dayjs().subtract(2, 'day').valueOf() < value;
};

function onQueryChangeGranularity() {
    emits('update:granularity', granularity.value)
    let currentQuery = {...router.currentRoute.value.query};
    currentQuery.granularity = granularity.value;
    router.replace({
        query: currentQuery,
    });
}

function onQueryChangeDate() {
    start.value = dayjs(dateRes.value.range[0]).format('YYYY-MM-DD');
    end.value = dayjs(dateRes.value.range[1]).format('YYYY-MM-DD');
    const params = {
        dateRes: dateRes.value,
        start_time: start.value,
        end_time: end.value,
    }
    emits('update:date', params)
    let currentQuery = {...router.currentRoute.value.query};
    currentQuery.start = start.value;
    currentQuery.end = end.value;
    router.replace({
        query: currentQuery,
    });
}

function onQueryChangeRefDate() {
    let params = {
        dateResRef: dateResRef.value,
        start_time: '',
        end_time: '',
    }
    if (dateResRef.value.range) {
        startRef.value = dayjs(dateResRef.value.range[0]).format('YYYY-MM-DD');
        endRef.value = dayjs(dateResRef.value.range[1]).format('YYYY-MM-DD');
        params = {
            dateResRef: dateResRef.value,
            start_time: startRef.value,
            end_time: endRef.value,
        }

        let currentQuery = {...router.currentRoute.value.query};
        currentQuery.startRef = startRef.value;
        currentQuery.endRef = endRef.value;
        router.replace({
            query: currentQuery,
        });
    } else {
        startRef.value = '';
        endRef.value = '';
        let currentQuery = {...router.currentRoute.value.query};
        delete currentQuery.startRef;
        delete currentQuery.endRef;
        router.replace({
            query: currentQuery,
        });
    
    }
    emits('update:refDate', params)
}

function updateCloudFilter() {
    dimensionList.value.cloud = cloudFilter.value === 'all' ? 'all' : cloudFilter.value;
    emits('update:dimension', dimensionList.value)
    emits('update:cloud', cloudFilter.value)
    let currentQuery = {...router.currentRoute.value.query};
    currentQuery.cloud = cloudFilter.value;
    router.replace({
        query: currentQuery,
    });
};
function updateRegionFilter() {
    dimensionList.value.region = regionFilter.value === 'all' ? 'all' : regionFilter.value;
    emits('update:dimension', dimensionList.value)
    emits('update:region', regionFilter.value)
    let currentQuery = {...router.currentRoute.value.query};
    currentQuery.region = regionFilter.value;
    router.replace({
        query: currentQuery,
    });
};

function onQueryResult() {
    emits('onQuery')
};

onMounted(() => {
    for (let key in regionMap) {
        regionList.value.push({
            label: regionMap[key]+'['+key+']',
            value: key,
        })
    }
})
</script>